<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="space-y-4 divide-y rounded-lg bg-white p-6 shadow">
      <div class="flex items-center justify-between">
        <h1 class="text-lg font-bold text-gray-900">
          Periode Tutup Buku Bonus
        </h1>
        <base-input type="text" label="Tahun">
          <base-select
            :options="yearOptions"
            v-model="filter.year"
            v-on:change="handleFilter"
          />
        </base-input>
      </div>
      <div class="pt-4">
        <mitra-period-table v-on:show="handleShow" />
        <mitra-period-modal
          read-only
          :visible="rowModal.visible"
          :mitra-period-id="rowModal.mitraPeriod.id"
          :year="filter.year"
          v-on:close="rowModal.visible = false"
          v-on:updated="handleUpdated"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MitraPeriodTable from '@/components/period/mitra-period/mitra-period-table.vue';
import MitraPeriodModal from '@/components/period/mitra-period/mitra-period-modal.vue';
import dayjs from 'dayjs';
import { mapActions } from 'vuex';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';

export default {
  name: 'SetupPeriodeTambahPointBonus',
  components: {
    MitraPeriodTable,
    MitraPeriodModal,
    BaseInput,
    BaseSelect,
  },
  data() {
    return {
      filter: {
        year: dayjs().year(),
      },
      rowModal: {
        visible: false,
        mitraPeriod: {
          id: null,
        },
      },
    };
  },
  computed: {
    yearOptions: function () {
      return Array(10)
        .fill(dayjs().year() + 9)
        .map((it, index) => (it -= index))
        .map((year) => ({
          key: year,
          label: year,
          value: year,
        }));
    },
  },
  methods: {
    ...mapActions({
      fetchMitraPeriods: 'mitra_periods/fetchMitraPeriods',
    }),
    handleFilter: function () {
      this.loadData();
    },
    handleUpdated: function () {
      this.loadData();
    },
    handleShow: function (mitraPeriod) {
      this.rowModal.mitraPeriod = mitraPeriod;
      this.rowModal.visible = true;
    },
    loadData: function () {
      this.fetchMitraPeriods({
        'filter[year]': this.filter.year,
      });
    },
  },
  created: function () {
    this.loadData();
  },
};
</script>
