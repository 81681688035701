<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <!-- Replace with your content -->
    <div class="flex justify-between">
      <h3 class="text-2xl font-semibold leading-6 text-gray-900">
        Selamat Datang, {{ me.name }}
      </h3>
      <span>{{ date }}</span>
    </div>
    <!-- /End replace -->
  </div>
</template>
<script>
import { StorageService } from '@/services/storage.service';
import dayjs from 'dayjs';

export default {
  data() {
    return {
      date: dayjs().format('MMMM YYYY'),
      me: StorageService.getUser(),
    };
  },
};
</script>
