<template>
  <div class="mx-auto flex flex-row bg-white">
    <section slot="pdf-content" class="w-full">
      <div
        class="html2pdf__page-break flex w-full flex-col items-center bg-white pl-6 pr-12"
        style="font-weight: 600"
      >
        <!-- Header -->
        <!-- kiri -->
        <div class="w-full text-left">
          <h1 class="text-md">PT. NATURAL NUSANTARA</h1>
        </div>
        <div class="grid w-full grid-cols-2 gap-y-6">
          <div class="flex max-w-xs flex-col">
            <address class="text-xs">
              Jl. Ring Road Barat No. 72 Salakan Trihanggo Gamping Sleman
              Jogjakarta 55291
            </address>
            <p class="text-xs">Telp:0274-6499191</p>
          </div>
          <div v-if="originOffice" class="flex max-w-xs flex-col">
            <p
              v-if="originOffice.attributes.office_type"
              class="text-xs capitalize"
            >
              {{ originOffice.attributes.office_type.split('_').join(' ') }} ({{
                originOffice.attributes.code
              }})
            </p>
            <p
              class="text-xs"
              v-if="originOffice.attributes.address_details?.length"
            >
              {{ originOffice.attributes.address_details[0].city }}
            </p>
            <address v-if="originOffice?.attributes.address" class="text-xs">
              {{ originOffice.attributes.address }}
            </address>
            <p class="text-xs">
              Telp: {{ originOffice.attributes.kta ?? '-' }}
            </p>
          </div>
        </div>

        <!-- Bill to -->
        <div class="mt-2 grid w-full grid-cols-2 justify-between">
          <div class="flex w-full flex-col" v-if="order.data">
            <h1 class="text-sm font-extrabold">Kepada:</h1>
            <p class="text-xs italic" v-if="order?.data.relationships">
              Bpk/Ibu
              {{
                getRelationships(
                  order,
                  order.data.relationships['destination-office'].data?.id
                )?.attributes.name ?? '-'
              }}
              {{
                getRelationships(
                  order,
                  order.data.relationships['destination-office'].data?.id
                )?.attributes.code + ','
              }}
              <span>
                <address>
                  {{
                    getRelationships(
                      order,
                      order.data.relationships['destination-office'].data?.id
                    )?.attributes.address
                  }},
                </address>
              </span>
              {{
                getRelationships(
                  order,
                  order.data.relationships['destination-office'].data?.id
                )?.attributes.phone
              }}
            </p>
          </div>
          <!-- kanan -->
          <div class="flex flex-col md:flex-shrink">
            <div class="flex flex-row items-center gap-x-1 text-xs">
              <p class="w-24 font-extrabold text-black">No.Invoice</p>
              <p>:</p>
              <p class="w-32 flex-shrink-0 font-bold">
                {{ order.data.attributes.origin_code }}
              </p>
            </div>
            <div class="flex flex-row items-center gap-x-1 text-xs">
              <p class="w-24 font-extrabold text-black">Tgl Invoice</p>
              <p>:</p>
              <p class="w-32 flex-shrink-0 font-bold">
                {{
                  dayjs(order.data.attributes.updatedAt).format('DD/MM/YYYY')
                }}
              </p>
            </div>
            <div class="flex flex-row items-center gap-x-1 text-xs">
              <p class="w-24 font-extrabold text-black">Waktu</p>
              <p>:</p>
              <p class="w-32 flex-shrink-0 font-bold">
                {{ dayjs(order.data.attributes.updatedAt).format('LT') }}
              </p>
            </div>
            <div class="flex flex-row items-center gap-x-1 text-xs">
              <p class="w-24 font-extrabold text-black">Wilayah</p>
              <p>:</p>
              <p class="w-32 flex-shrink-0 font-bold">
                {{ order.data.attributes.area_name }} ({{
                  order.data.attributes.area_code
                }})
              </p>
            </div>
          </div>
        </div>

        <!-- Barang Point -->
        <div
          v-for="items in stockSummary"
          :key="items.label"
          class="mt-3 flex w-full justify-between gap-x-6"
        >
          <div v-if="items.value.length > 0" class="flex w-full flex-col">
            <h1 class="text-sm uppercase">{{ items.label }}</h1>
            <table class="text-xs">
              <thead>
                <tr>
                  <td class="py-2">Kode</td>
                  <td class="py-2">Nama Barang</td>
                  <td class="py-2">Jumlah</td>
                  <td class="py-2">Total BV</td>
                  <td class="py-2">Harga Stc</td>
                  <td class="py-2">Total Harga</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="text-xs"
                  v-for="(stock, index) in items.value"
                  :key="index"
                  style="
                    line-height: 0.5rem;
                    border-top: 1px dashed black;
                    border-bottom: 1px dashed black;
                  "
                >
                  <td class="relative bottom-2 py-2">
                    {{ stock.attributes.product_code }}
                  </td>
                  <td class="relative bottom-2 py-2">
                    {{ stock.attributes.product_name }}
                  </td>
                  <td class="relative bottom-2 py-2">
                    {{ stock.attributes.product_qty | toCurrency }}
                  </td>
                  <td class="relative bottom-2 py-2">
                    {{ parseInt(stock.attributes.bonus_value) | toCurrency }}
                  </td>
                  <td class="relative bottom-2 py-2">
                    {{ stock.attributes.product_price | toCurrency }}
                  </td>
                  <td class="relative bottom-2 py-2">
                    {{ stock.attributes.total_price | toCurrency }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="2" class="py-2"></td>
                  <td class="font-base text-xs">
                    <!-- Sub BV -->
                  </td>
                  <td class="text-xs font-extrabold">
                    {{ getSubBonusValue(items.value) | toCurrency }}
                  </td>
                  <td class="font-base text-xs">Sub Total</td>
                  <td class="text-xs font-extrabold">
                    {{ getGrandTotalPrice(items.value) | toCurrency }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>

        <!-- Footer -->
        <div class="mt-4 flex w-full flex-col justify-between">
          <footer class="flex w-full flex-col items-end justify-end">
            <div
              style="border-top: 1px dashed black"
              class="w-full border-gray-700"
            >
              <div class="flex justify-end">
                <div
                  class="flex flex-row gap-x-2"
                  style="border-bottom: 1px dashed black"
                >
                  <div class="relative bottom-2 sm:col-span-1">
                    <label for="invoice" class="font-base block text-xs">
                      Total Pembelian
                    </label>
                  </div>
                  <div class="relative bottom-2 sm:col-span-1">
                    <label for="invoice" class="block text-xs font-extrabold">
                      {{ getGrandTotalPrice(order_details) | toCurrency }}
                    </label>
                  </div>
                </div>
              </div>
              <!-- dasar pengenaan pajak -->
              <div class="flex justify-end">
                <div class="flex flex-row gap-x-2">
                  <div class="sm:col-span-1">
                    <label for="invoice" class="font-base block text-xs">
                      Dasar Pengenaan Pajak
                    </label>
                  </div>
                  <div class="sm:col-span-1">
                    <label for="invoice" class="block text-xs font-extrabold">
                      {{ dpp | toCurrency }}
                    </label>
                  </div>
                </div>
              </div>
              <!-- ppn -->
              <div class="flex justify-end">
                <div class="flex flex-row gap-x-2">
                  <div class="sm:col-span-1">
                    <label for="invoice" class="font-base block text-xs">
                      PPN 11%
                    </label>
                  </div>
                  <div class="sm:col-span-1">
                    <label for="invoice" class="block text-xs font-extrabold">
                      {{ ppn | toCurrency }}
                    </label>
                  </div>
                </div>
              </div>

              <div class="flex flex-col pt-4">
                <div class="flex flex-row justify-between gap-x-2">
                  <div class="sm:col-span-1">
                    <label for="invoice" class="font-base block text-xs">
                      Catatan: {{ order.data.attributes.notes ?? '-' }}
                    </label>
                  </div>
                </div>
                <div class="flex flex-row justify-between gap-x-2">
                  <div class="sm:col-span-1">
                    <label for="invoice" class="font-base block text-xs">
                      Kode Gudang:
                      {{
                        getRelationships(
                          order,
                          order.data.relationships['destination-warehouse']
                            ?.data?.id
                        )?.attributes.code ?? '-'
                      }}
                    </label>
                    <label for="invoice" class="font-base block text-xs">
                      Tanggal & Jam Cetak:
                      {{ dayjs().format('DD/MM/YY HH:mm') }}
                    </label>
                  </div>
                </div>
              </div>

              <div class="my-6 flex w-full justify-end">
                <div
                  class="mt-3 border-t-2 border-dashed border-gray-700 sm:col-span-1"
                >
                  <label for="invoice" class="block text-xs font-extrabold">
                    {{ getBagian() }}
                  </label>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';

export default {
  components: {},

  props: {
    order: {
      type: Object,
      default: () => ({
        id: '',
        attributes: {},
      }),
    },
    withOriginAddress: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dataPenjualan: {},
    };
  },
  computed: {
    ...mapGetters({
      getLoading: 'orders/getLoading',
    }),
    originOffice() {
      return this.order.included.find((it) => {
        return (
          it.id == this.order?.data.relationships['origin-office']?.data.id
        );
      });
    },
    order_details() {
      return this.order.data.relationships?.['order-details']?.data?.map((it) =>
        this.getRelationships(this.order, it.id)
      );
    },
    barangPoint() {
      return (
        this.order.included?.filter(
          (it) => it.attributes.is_point && it.type == 'order-details'
        ) || []
      );
    },
    barangNonPoint() {
      return (
        this.order.included?.filter(
          (it) => !it.attributes.is_point && it.type == 'order-details'
        ) || []
      );
    },
    stockSummary() {
      return [
        {
          label: 'Barang Berpoint',
          value: this.barangPoint,
        },
        {
          label: 'Barang Non Point',
          value: this.barangNonPoint,
        },
      ];
    },
    dpp() {
      return this.getGrandTotalPrice(this.order_details) * (100 / 111);
    },
    ppn() {
      return this.dpp * (11 / 100);
    },
  },
  methods: {
    dayjs,
    getSubBonusValue(value) {
      return value?.reduce(function (total, current) {
        return total + (parseInt(current.attributes.bonus_value) ?? 0);
      }, 0);
    },
    getBagian() {
      const router = this.$router;
      if (router.currentRoute.path.includes('penjualan')) {
        return 'Bag. Penjualan';
      } else if (router.currentRoute.path.includes('gudang')) {
        return 'Bag. Gudang';
      } else if (router.currentRoute.path.includes('kasir')) {
        return 'Bag. Kasir';
      }
      return '';
    },
    getGrandTotalPrice(value) {
      return value?.reduce(function (total, current) {
        return total + (current.attributes.total_price ?? 0);
      }, 0);
    },
    getGrandTotalWeight(value) {
      return value?.reduce(function (total, current) {
        return total + parseFloat(current.total_weight);
      }, 0);
    },
    getGrandTotalBV(value) {
      return value?.reduce(function (total, current) {
        return total + ((current.bonus_value || 0) * current.product_qty || 0);
      }, 0);
    },
    getRelationships(resource, id) {
      let data = resource.included?.find(function (el) {
        return el.id === id;
      });
      return data;
    },
  },
};
</script>
<style lang="postcss">
.html2pdf__page-break {
  font-family: 'Courier New', Courier, monospace !important;
}
</style>
