<template>
  <Datatable
    :total="getOrders.meta.page.total"
    :perPage="getOrders.meta.page.perPage"
    :currentPage="getOrders.meta.page.currentPage"
    @pagechanged="onPageChangeOrder"
  >
    <template v-slot:thead>
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            No. Cashbill
          </th>
          <th
            scope="col"
            class="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Mitra Usaha
          </th>
          <th
            scope="col"
            class="px-4 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            PV
          </th>
          <th
            scope="col"
            class="px-4 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            BV
          </th>
          <th
            scope="col"
            class="px-4 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Upline
          </th>
          <th
            scope="col"
            class="px-4 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Total Harga Mitra Usaha
          </th>
          <th
            scope="col"
            class="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Status Laporan
          </th>
          <th
            scope="col"
            class="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Status Faktur
          </th>
        </tr>
      </thead>
    </template>
    <template v-slot:tbody class="relative">
      <div v-if="isLoading" class="flex items-center text-center">
        <loading></loading>
      </div>
      <tbody v-if="getOrders.data.length">
        <tr
          class="cursor-pointer bg-white hover:bg-green-100"
          v-for="(data, dataIdx) in getOrders.data"
          :key="data.id"
          :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
          @click.prevent="onClickRow(data)"
        >
          <td class="whitespace-nowrap px-4 py-4 text-sm text-gray-900">
            <div class="text-sm font-bold text-gray-900">
              {{ data.attributes.origin_code }} /
              <span class="text-xs text-gray-400">{{
                data.attributes.destination_code
              }}</span>
            </div>
            <div class="text-xs text-gray-500">
              {{ dayjs(data.attributes.updatedAt).format('ll LT') }}
            </div>
          </td>
          <td class="whitespace-nowrap px-4 py-4 text-sm text-gray-500">
            <p class="font-bold text-gray-900">
              {{
                data.relationships['destination-office'].data
                  ? getSingleIncluded(
                    getOrders,
                    data.relationships['destination-office'].data.id
                  ).attributes.code
                  : '-'
              }}
            </p>
            <p class="text-xs text-gray-500">
              {{
                data.relationships['destination-office'].data
                  ? getSingleIncluded(
                    getOrders,
                    data.relationships['destination-office'].data.id
                  ).attributes.name
                  : '-'
              }}
            </p>
          </td>
          <td
            class="whitespace-nowrap px-4 py-4 text-right text-sm text-gray-500"
          >
            {{
              getSingleIncluded(getOrders, data.relationships['order'].data.id)
                .attributes.grand_total_pv | toCurrency
            }}
          </td>
          <td
            class="whitespace-nowrap px-4 py-4 text-right text-sm text-gray-500"
          >
            {{
              getSingleIncluded(getOrders, data.relationships['order'].data.id)
                .attributes.total_bonus_value | toCurrency
            }}
          </td>
          <td
            class="whitespace-nowrap px-4 py-4 text-center text-sm text-gray-500"
          >
            <p class="text-gray-700">
              {{
                data.relationships['destination-office'].data
                  ? getSingleIncluded(
                    getOrders,
                    data.relationships['destination-office'].data.id
                  ).attributes.referred_by ?? '-'
                  : '-'
              }}
            </p>
            <p class="text-xs text-gray-500">
              {{
                data.relationships['destination-office'].data
                  ? getSingleIncluded(
                    getOrders,
                    data.relationships['destination-office'].data.id
                  ).attributes.referred_by_name ?? '-'
                  : '-'
              }}
            </p>
          </td>
          <td
            class="whitespace-nowrap px-4 py-4 text-right text-sm text-gray-500"
          >
            {{
              getSingleIncluded(getOrders, data.relationships['order'].data.id)
                .attributes.final_price | toCurrency
            }}
          </td>
          <td
            class="whitespace-nowrap px-4 py-4 text-left text-sm text-gray-500"
          >
            <span
              v-if="data.attributes.order_status == 'Bonus Terlaporkan'"
              class="inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800"
            >
              Dilaporkan
            </span>
            <span
              v-else
              class="inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-700"
            >
              Belum Dilaporkan
            </span>
          </td>
          <td
            class="whitespace-nowrap px-4 py-4 text-left text-sm text-gray-500"
          >
            <span
              class="inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800"
            >
              {{ data.attributes.order_status }}
            </span>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr class="bg-white">
          <td
            class="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-900"
            colspan="10"
          >
            Data tidak ditemukan
          </td>
        </tr>
      </tbody>
    </template>
  </Datatable>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

export default {
  name: 'CashbillTable',
  props: {},
  data() {
    return {
      // Your component data here
    };
  },
  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      isLoading: 'orders/getLoading',
    }),
  },
  components: {
    // Your child components here
  },
  methods: {
    dayjs,
    onPageChangeOrder(val) {
      this.$emit('page-change', val);
    },
    onClickRow(val) {
      this.$emit('click-row', val);
    },
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
