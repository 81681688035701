<template>
  <div id="modal" class="fixed inset-0 z-20 overflow-y-auto">
    <div
      class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
        @click="$emit('close-modal')"
      ></div>
      <span
        class="hidden sm:inline-block sm:h-screen sm:align-middle"
        aria-hidden="true"
        >&#8203;</span
      >
      <!-- Modal content -->
      <div
        class="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:w-full sm:max-w-lg sm:align-middle"
      >
        <!-- Modal header -->
        <div
          v-if="header"
          class="flex items-center justify-between rounded-t border-b p-5 dark:border-gray-600"
          :class="colorheading"
        >
          <slot name="heading"> </slot>
          <button
            @click="$emit('close-modal')"
            type="button"
            class="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <svg
              class="h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- Modal body -->
        <div v-if="body" class="p-6 text-center" :class="colorbody">
          <slot name="body"> Modal Body </slot>
        </div>
        <!-- Modal footer -->
        <div
          v-if="footer"
          :class="colorfooter"
          class="flex flex-col items-center gap-y-2 space-x-2 rounded-b border-t border-gray-200 p-6 dark:border-gray-600"
        >
          <slot name="footer"> Modal Footer </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Modal2',
  props: {
    actions: {
      type: Boolean,
      default: true,
    },
    colorheading: {
      type: String,
      default: 'bg-white',
    },
    colorbody: {
      type: String,
      default: 'bg-white',
    },
    colorfooter: {
      type: String,
      default: 'bg-white',
    },
    header: {
      type: Boolean,
      default: true,
    },
    body: {
      type: Boolean,
      default: true,
    },
    footer: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    const onEscape = (e) => {
      if (this.open && e.keyCode === 27) {
        this.close();
      }
    };
    document.addEventListener('keydown', onEscape);
    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', onEscape);
    });
  },
};
</script>
