<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg py-4">
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <base-search
            v-model="search"
            @input="debounceSearch"
            placeholder="Cari kode atau nama"
          />
        </div>
        <div class="flex my-2 sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="listStockist.meta.page.total"
              :perPage="listStockist.meta.page.perPage"
              :currentPage="listStockist.meta.page.currentPage"
              @pagechanged="onPageChangeStockist"
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode Stockist
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nama Stockist
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      No. Rekening
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      No. Telp
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Alamat Stockist
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody>
                  <tr
                    class="cursor-pointer bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in listStockist.data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    @click.stop="detailStockist(data)"
                  >
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                    >
                      {{ data.attributes.code }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.name }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      <span v-if="data.attributes.bank_account_number">{{
                        data.attributes.bank_account_number
                      }}</span>
                      <span v-else>-</span>
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.phone }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.email }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.address }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      <button
                        v-if="
                          data.attributes.office_type === 'stockist' &&
                          data.attributes.is_promoted === false &&
                          !['KYN', 'SCN', 'PRSH'].some((item) =>
                            data.attributes.code.includes(item)
                          )
                        "
                        @click.stop="openPromote(data, 'promoteToSC')"
                        class="rounded-xl bg-green-100 px-4 py-1 text-sm text-green-800"
                      >
                        Promosi ke SC
                      </button>
                      <button
                        v-else-if="
                          data.attributes.office_type === 'stockist' &&
                          data.attributes.is_promoted === true
                        "
                        class="rounded-xl bg-blue-100 px-4 py-1 text-sm text-blue-800"
                      >
                        {{ data.attributes.promoted_by }}
                      </button>
                      <button
                        v-else-if="
                          data.attributes.office_type === 'stockist_center'
                        "
                        class="rounded-xl bg-blue-100 px-4 py-1 text-sm text-blue-800"
                      >
                        Dipromosikan ke SC
                      </button>
                      <button
                        v-else
                        class="rounded-xl bg-gray-100 px-4 py-1 text-sm text-gray-800"
                      >
                        Tidak ada aksi
                      </button>
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>
        <div
          v-if="openAdd"
          class="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
              >&#8203;</span
            >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
            >
              <form @submit.prevent="addStockist" id="addStockistForm">
                <div>
                  <div
                    class="flex justify-between border-b border-gray-200 px-4 py-5 sm:px-6"
                  >
                    <div>
                      <h3 class="text-lg font-semibold leading-6 text-gray-900">
                        Tambah Stockist
                      </h3>
                    </div>
                  </div>
                  <div class="py-2 px-6">
                    <div class="flex mt-4 sm:flex-row sm:gap-x-4">
                      <div class="field-inset-default relative w-full">
                        <label
                          for="kode_stockist"
                          class="block text-xs font-bold text-gray-700"
                        >
                          Kode Stockist
                        </label>
                        <button
                          type="button"
                          @click="openModal"
                          class="flex absolute inset-y-0 top-4 right-4 cursor-pointer items-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5 text-gray-500 hover:text-gray-700"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                        <input
                          v-model="dataStockist.kode_stockist"
                          ref="focusNamaBarang"
                          @blur="searchStockist"
                          type="text"
                          name="kode_stockist"
                          id="kode_stockist"
                          class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                          placeholder="Masukkan Nama Stockist"
                        />
                      </div>
                      <div class="field-inset-default w-full">
                        <label
                          for="nama_stockist"
                          class="block text-xs font-bold text-gray-700"
                        >
                          Nama Stockist
                        </label>
                        <input
                          v-model="dataStockist.nama_stockist"
                          typeref="focusNamaStockist"
                          type="text"
                          name="nama_stockist"
                          id="nama_stockist"
                          class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                          placeholder="Masukkan Nama Stockist"
                        />
                      </div>
                    </div>
                    <div class="flex mt-4 sm:flex-row sm:gap-x-4">
                      <div class="field-inset-default w-full">
                        <label
                          for="no_hp"
                          class="block text-xs font-bold text-gray-700"
                        >
                          No.Hp
                        </label>
                        <input
                          v-model="dataStockist.phone"
                          type="text"
                          name="no_hp"
                          id="no_hp"
                          class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                          placeholder="Masukkan nomor handphone pemilik stockist"
                        />
                      </div>
                      <div class="field-inset-default w-full">
                        <label
                          for="email"
                          class="block text-xs font-bold text-gray-700"
                        >
                          Email
                        </label>
                        <input
                          v-model="dataStockist.email"
                          typeref="focusNamaStockist"
                          type="text"
                          name="email"
                          id="email"
                          class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                          placeholder="Masukkan email Stockist"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    class="flex justify-between border-b border-gray-200 px-4 py-5 sm:px-6"
                  >
                    <div>
                      <h3 class="text-lg font-semibold leading-6 text-gray-900">
                        Informasi Alamat
                      </h3>
                    </div>
                  </div>
                  <div class="py-2 px-6">
                    <div class="flex mt-4 sm:flex-row sm:gap-x-4">
                      <div class="field-inset-default w-full">
                        <label
                          for="provinsi"
                          class="block text-xs font-bold text-gray-700"
                        >
                          Provinsi
                        </label>
                        <select
                          v-model="dataStockist.provinsi"
                          @change="changeProvince"
                          id="provinsi"
                          class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                          placeholder="Masukkan Stok Barang"
                        >
                          <option
                            :value="{
                              id: '',
                              name: '',
                            }"
                          >
                            Pilih Provinsi
                          </option>
                          <option
                            v-for="provinsi in getProvinces.data"
                            :key="provinsi.id"
                            :value="{
                              id: provinsi.id,
                              name: provinsi.attributes.name,
                            }"
                          >
                            {{ provinsi.attributes.name }}
                          </option>
                        </select>
                      </div>
                      <div class="field-inset-default w-full">
                        <label
                          for="kabupaten"
                          class="block text-xs font-bold text-gray-700"
                        >
                          Kabupaten
                        </label>
                        <select
                          v-model="dataStockist.kota"
                          @change="changeCity"
                          id="kabupaten"
                          class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                          placeholder="Masukkan Stok Barang"
                        >
                          <option
                            :value="{
                              id: '',
                              name: '',
                              area: '',
                            }"
                          >
                            Pilih Kabupaten
                          </option>
                          <option
                            v-for="kota in getCities.data"
                            :key="kota.id"
                            :value="{
                              id: kota.id,
                              name: kota.attributes.name,
                              area: kota.attributes.area_code,
                            }"
                          >
                            {{ kota.attributes.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="flex mt-4 sm:flex-row sm:gap-x-4">
                      <div class="field-inset-default w-full">
                        <label
                          for="kecamatan"
                          class="block text-xs font-bold text-gray-700"
                        >
                          Kecamatan
                        </label>
                        <select
                          v-model="dataStockist.kecamatan"
                          @change="changeDistrict"
                          id="kecamatan"
                          class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                          placeholder="Masukkan Stok Barang"
                        >
                          <option
                            :value="{
                              id: '',
                              name: '',
                            }"
                          >
                            Pilih Kecamatan
                          </option>
                          <option
                            v-for="kecamatan in getDistricts.data"
                            :key="kecamatan.id"
                            :value="{
                              id: kecamatan.id,
                              name: kecamatan.attributes.name,
                            }"
                          >
                            {{ kecamatan.attributes.name }}
                          </option>
                        </select>
                      </div>
                      <div class="field-inset-default w-full">
                        <label
                          for="desa"
                          class="block text-xs font-bold text-gray-700"
                        >
                          Desa
                        </label>
                        <select
                          v-model="dataStockist.desa"
                          id="desa"
                          class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                          placeholder="Masukkan Stok Barang"
                        >
                          <option
                            :value="{
                              id: '',
                              name: '',
                            }"
                          >
                            Pilih Desa
                          </option>
                          <option
                            v-for="desa in getVillages.data"
                            :key="desa.id"
                            :value="{
                              id: desa.id,
                              name: desa.attributes.name,
                            }"
                          >
                            {{ desa.attributes.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="flex mt-4 sm:flex-row sm:gap-x-4">
                      <div class="field-inset-default w-full">
                        <label
                          for="alamat"
                          class="block text-xs font-bold text-gray-700"
                        >
                          Alamat Suplier
                        </label>
                        <input
                          v-model="dataStockist.alamat"
                          type="text"
                          name="alamat"
                          id="alamat"
                          class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                          placeholder="Masukkan Alamat Suplier"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pt-5">
                  <div class="flex justify-end">
                    <button
                      type="submit"
                      class="ml-3 inline-flex rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg"
                    >
                      Simpan
                    </button>
                    <button
                      @click="clearDataStockist"
                      type="button"
                      class="bg-white-600 ml-3 rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
                    >
                      Batal
                    </button>
                    <button
                      @click="openAdd = !openAdd"
                      class="ml-3 inline-flex rounded-md border border-gray-300 bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-600 hover:shadow-lg"
                    >
                      Selesai
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Modal
          :showing="openDetail"
          @close="openDetail = false"
          :showClose="openDetail"
          :backgroundClose="true"
          size="max-w-6xl"
        >
          <div class="flex justify-between px-4 py-5 sm:px-6">
            <div>
              <h3 class="text-lg font-bold leading-6 text-gray-900">
                Detail Stockist
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Berdasarkan data stockist yang telah dibuat
              </p>
            </div>
            <div>
              <img src="@/assets/images/logo.png" alt="" class="w-52" />
            </div>
          </div>
          <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6 sm:pb-6">
              <div class="sm:col-span-3">
                <dt class="text-sm font-bold text-gray-700">Nama Stockist</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ dataStockist.nama_stockist }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">Kode Stockist</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ dataStockist.kode_stockist }}
                </dd>
              </div>
              <div class="sm:col-span-1">
                <dd class="mt-1 text-sm text-gray-900">
                  <toggle-button
                    v-model="dataStockist.is_suspended"
                    :labels="{
                      checked: 'Aktif',
                      unchecked: 'Non Aktif',
                    }"
                    :width="dataStockist.is_suspended ? 60 : 80"
                    v-on:change="onActiveChange"
                  />
                </dd>
              </div>
              <div class="sm:col-span-3">
                <dt class="text-sm font-bold text-gray-700">No. Hp</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ dataStockist.phone }}
                </dd>
              </div>
              <div class="sm:col-span-3">
                <dt class="text-sm font-bold text-gray-700">Email</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ dataStockist.email }}
                </dd>
              </div>
              <div class="sm:col-span-3">
                <dt class="text-sm font-bold text-gray-700">Provinsi</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ dataStockist.provinsi.name }}
                </dd>
              </div>
              <div class="sm:col-span-3">
                <dt class="text-sm font-bold text-gray-700">Kabupaten</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ dataStockist.kota.name }}
                </dd>
              </div>
              <div class="sm:col-span-3">
                <dt class="text-sm font-bold text-gray-700">Kecamatan</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ dataStockist.kecamatan.name }}
                </dd>
              </div>
              <div class="sm:col-span-3">
                <dt class="text-sm font-bold text-gray-700">Desa</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ dataStockist.desa.name }}
                </dd>
              </div>
              <div class="sm:col-span-3">
                <dt class="text-sm font-bold text-gray-700">Alamat Stockist</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ dataStockist.alamat }}
                </dd>
              </div>
              <div class="sm:col-span-3">
                <dt class="text-sm font-bold text-gray-700">Wilayah Harga</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ dataStockist.area?.attributes?.name }} ({{
                    dataStockist.area?.attributes?.code
                  }})
                </dd>
              </div>
              <div class="sm:col-span-3">
                <dt class="text-sm font-bold text-gray-700">Dibuat</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ dataStockist.attributes?.createdAt | formatDate }}
                </dd>
              </div>
              <div class="sm:col-span-3">
                <dt class="text-sm font-bold text-gray-700">
                  Terakhir Diperbarui
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ dataStockist.attributes?.updatedAt | formatDate }}
                </dd>
              </div>
            </dl>
          </div>
          <section class="px-6">
            <h3 class="mb-4 text-lg font-bold leading-6 text-gray-900">
              Daftar User di Kantor Ini
            </h3>
            <Datatable
              :total="dataStockist.users.meta.page.total"
              :perPage="dataStockist.users.meta.page.perPage"
              :currentPage="dataStockist.users.meta.page.currentPage"
              :paginated="false"
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nama
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Role
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody v-if="dataStockist.users.data.length > 0">
                  <tr
                    class="bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in dataStockist.users.data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                  >
                    <td
                      class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.name }}
                    </td>
                    <td
                      class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.email }}
                    </td>
                    <td
                      class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.current_role }}
                    </td>
                    <td
                      class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      <toggle-button
                        :value="!data.attributes.is_suspended"
                        :labels="{
                          checked: 'Aktif',
                          unchecked: 'Non Aktif',
                        }"
                        :width="data.attributes.is_suspended ? 60 : 80"
                        v-on:change="onActiveUserChange(data)"
                      />
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr class="bg-white">
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      colspan="4"
                    >
                      Data tidak ditemukan
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </section>

          <div class="flex mx-8 justify-end">
            <button
              @click="openDetail = !openDetail"
              type="button"
              class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
            >
              Tutup
            </button>
          </div>
        </Modal>

        <transition
          name="customFade"
          enter-active-class="fadeIn"
          leave-active-class="fadeOut"
        >
          <div
            v-if="openListStockist"
            class="fixed inset-0 z-10 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div
              class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
            >
              <div
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <span
                class="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
                >&#8203;</span
              >
              <div
                class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
              >
                <div class="flex justify-between px-4 py-5 sm:px-6">
                  <div>
                    <h3 class="text-lg font-bold leading-6 text-gray-900">
                      Daftar Stockist
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                      Berdasarkan data barang yang telah dibuat
                    </p>
                  </div>
                  <div>
                    <img src="@/assets/images/logo.png" alt="" class="w-52" />
                  </div>
                </div>
                <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <div class="flex justify-end">
                    <base-search
                      v-model="searchStockistDetail"
                      @input="debounceSearchDetail"
                      placeholder="Cari kode atau nama"
                    />
                  </div>
                  <div class="flex my-2 sm:-mx-6 lg:-mx-8">
                    <div
                      class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
                    >
                      <Datatable
                        :total="dataDetailStockist.meta.page.total"
                        :perPage="dataDetailStockist.meta.page.perPage"
                        :currentPage="dataDetailStockist.meta.page.currentPage"
                        @pagechanged="onPageChangeDetailStockist"
                      >
                        <template v-slot:thead>
                          <thead class="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                Kode Stockist
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                Nama Stockist
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                No. Telp
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                Email
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                Alamat Stockist
                              </th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:tbody>
                          <tbody>
                            <tr
                              class="cursor-pointer bg-white hover:bg-green-100"
                              v-for="(data, dataIdx) in dataDetailStockist.data"
                              :key="data.id"
                              :class="
                                dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                              "
                              @click="changeStockist(data)"
                            >
                              <td
                                class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                              >
                                {{ data.attributes.code }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                              >
                                {{ data.attributes.name }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                              >
                                {{ data.attributes.phone }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                              >
                                {{ data.attributes.email }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                              >
                                {{ data.attributes.address }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </Datatable>
                    </div>
                  </div>
                </div>
                <div class="flex justify-end">
                  <button
                    @click="openListStockist = !openListStockist"
                    type="button"
                    class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                  >
                    Tutup
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div
            v-if="success"
            class="fixed inset-0 z-10 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div
              class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
            >
              <div
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <span
                class="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
                >&#8203;</span
              >
              <div
                class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
              >
                <div>
                  <div
                    class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-green-100"
                  >
                    <svg
                      class="h-6 w-6 text-green-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <slot name="heading"> Data Berhasil disimpan </slot>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6">
                  <div class="flex items-center gap-2">
                    <button
                      @click.prevent="addMoreStockist"
                      class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                    >
                      Tambah Lagi
                    </button>
                    <button
                      @click="closeModal"
                      class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-700 focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:text-sm"
                    >
                      Selesai
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div
            v-if="failed"
            class="fixed inset-0 z-10 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div
              class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
            >
              <div
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <span
                class="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
                >&#8203;</span
              >
              <div
                class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
              >
                <div>
                  <div
                    class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-red-100"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-red-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <slot name="heading">
                      {{ getError }}
                    </slot>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6">
                  <div class="flex items-center gap-2">
                    <button
                      @click.prevent="failedModal"
                      class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                    >
                      Ulangi
                    </button>
                    <button
                      @click="closeModal"
                      class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-700 focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:text-sm"
                    >
                      Selesai
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <Modal
      :showing="modalType === 'promoteToSC'"
      @close="modalType = null"
      :showClose="modalType === 'promoteToSC'"
      :backgroundClose="true"
      size="max-w-xl"
    >
      <form @submit.prevent="promoteStockistToSC">
        <div>
          <div class="mt-3 text-center sm:mt-5">
            <h3
              class="text-lg font-medium leading-6 text-gray-900"
              id="modal-title"
            >
              Promosi Kantor
            </h3>
            <div class="mt-2">
              <p class="text-sm text-gray-500">
                Lengkapi form dibawah ini untuk melakukan promosi
              </p>
            </div>
          </div>
          <div class="my-4 space-y-3">
            <div class="space-y-3">
              <div class="field-inset-default w-full">
                <label for="code" class="block text-xs font-bold text-gray-700">
                  Kode Kantor
                </label>
                <input
                  v-model="promote.code"
                  type="text"
                  name="code"
                  id="code"
                  class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan kode kantor (kode kantor harus berbeda)"
                />
              </div>
              <div class="field-inset-default w-full">
                <label
                  for="email"
                  class="block text-xs font-bold text-gray-700"
                >
                  Email
                </label>
                <input
                  v-model="promote.email"
                  type="email"
                  name="email"
                  id="email"
                  class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan email baru (contoh: memberbaru@naturalnusantara.co.id)"
                />
              </div>
              <div class="field-inset-default w-full">
                <label
                  for="no_account_gl"
                  class="block text-xs font-bold text-gray-700"
                >
                  No Account GL
                </label>
                <input
                  v-model="promote.no_account_gl"
                  type="text"
                  name="no_account_gl"
                  id="no_account_gl"
                  class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan No Account GL baru (contoh: 2342ABFDS"
                />
              </div>
              <div class="field-inset-default w-full">
                <label
                  for="cost_center"
                  class="block text-xs font-bold text-gray-700"
                >
                  Cost Center
                </label>
                <input
                  v-model="promote.cost_center"
                  type="text"
                  name="cost_center"
                  id="cost_center"
                  class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan No Cost Center (wajib)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
          <button
            type="submit"
            class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
          >
            Promosi
          </button>
          <button
            @click="modalType = null"
            type="button"
            class="focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
          >
            Tutup
          </button>
        </div>
      </form>
    </Modal>
    <loading v-if="isLoading" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import Modal from '@/components/base/BaseModal';
import ApiService from '@/services/api.service';
import { ToggleButton } from 'vue-js-toggle-button';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'Home',
  components: {
    Datatable,
    Modal,
    ToggleButton,
    BaseSearch,
  },
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      modalType: '',
      selectedOffice: {},
      type: '',
      search: null,
      searchStockistDetail: '',
      anyStockist: false,
      openListStockist: false,
      openAdd: false,
      openDetail: false,
      success: false,
      failed: false,
      stockist_office_category: null,
      promote: {
        code: null,
        email: null,
        no_account_gl: null,
        cost_center: null,
      },
      dataDetailStockist: {
        data: [],
        meta: {
          page: {},
        },
      },
      listStockist: {
        data: [],
        meta: {
          page: {},
        },
      },
      dataStockist: {
        id: '',
        kode_stockist: '',
        nama_stockist: '',
        phone: '',
        email: '',
        alamat: '',
        users: {
          data: [],
          meta: {
            page: {
              total: '',
              perPage: '',
              currentPage: '',
            },
          },
        },
        provinsi: {
          id: '',
          name: '',
        },
        kota: {
          id: '',
          name: '',
          area: '',
        },
        kecamatan: {
          id: '',
          name: '',
        },
        desa: {
          id: '',
          name: '',
        },
        area: null,
      },
    };
  },
  created() {
    this.fetchStockists({
      pageNumber: 1,
      pageSize: 5,
    }).then((response) => {
      this.listStockist = response.data;
    });
    this.stockist_office_category = this.getAvailableOfficeCategories.filter(
      function (el) {
        return el.attributes.name === 'Stockist';
      }
    );

    const handleEscape = () => {
      this.openDetail =
        this.openListStockist =
        this.success =
        this.failed =
        this.openAdd =
          false;
      this.search = '';
    };
    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      getProvinces: 'indonesia/getProvinces',
      getCities: 'indonesia/getCities',
      getDistricts: 'indonesia/getDistricts',
      getVillages: 'indonesia/getVillages',
      getStockists: 'stockists/getStockists',
      getError: 'stockists/getError',
      getAvailableOfficeCategories:
        'office_categories/getAvailableOfficeCategories',
    }),
  },
  methods: {
    ...mapActions({
      fetchProvinces: 'indonesia/fetchProvinces',
      fetchCities: 'indonesia/fetchCities',
      fetchDistricts: 'indonesia/fetchDistricts',
      fetchVillages: 'indonesia/fetchVillages',
      fetchStockists: 'stockists/fetchStockists',
      createStockist: 'stockists/createStockist',
      updateStockist: 'stockists/updateStockist',
      updateAddress: 'addresses/updateAddress',
      promoteToSC: 'offices/promoteToSC',
      fetchOffices: 'offices/fetchOffices',
      updateOffice: 'offices/updateOffice',
      updateUser: 'users/updateUser',
    }),
    onActiveUserChange(user) {
      const payload = {
        data: {
          type: 'users',
          id: user.id,
          attributes: {
            is_suspended: !user.attributes.is_suspended,
          },
        },
      };
      this.updateUser(payload);
    },
    openAddStockist() {
      this.openAdd = !this.openAdd;
      this.fetchProvinces({
        pageNumber: 1,
      });
      this.clearDataStockist();
    },
    openModal() {
      this.openListStockist = !this.openListStockist;
      this.fetchStockists({
        pageNumber: 1,
        pageSize: 5,
      }).then((response) => {
        this.dataDetailStockist = response.data;
      });
    },
    closeModal() {
      this.success = this.failed = false;
      this.clearDataStockist();
      this.openAdd = !this.openAdd;
      this.fetchStockists({
        pageNumber: 1,
        pageSize: 5,
      });
    },
    addMoreStockist() {
      this.success = !this.success;
      this.clearDataStockist();
    },
    failedModal() {
      this.failed = !this.failed;
      this.clearDataStockist();
    },
    debounceSearch: debounce(function () {
      this.fetchStockists({
        pageNumber: 1,
        pageSize: 5,
        search: this.search,
      }).then((response) => {
        this.listStockist = response.data;
      });
    }, 300),
    debounceSearchDetail: debounce(function () {
      this.fetchStockists({
        pageNumber: 1,
        pageSize: 5,
        search: this.searchStockistDetail,
      }).then((response) => {
        this.dataDetailStockist = response.data;
      });
    }, 300),
    onPageChangeStockist(page) {
      this.fetchStockists({
        pageNumber: page,
        pageSize: 5,
        search: this.search,
      }).then((response) => {
        this.listStockist = response.data;
      });
    },
    onPageChangeDetailStockist(page) {
      this.fetchStockists({
        pageNumber: page,
        pageSize: 5,
        search: this.searchStockistDetail,
      }).then((response) => {
        this.dataDetailStockist = response.data;
      });
    },
    async detailStockist(stockist) {
      this.dataStockist.id = stockist.id;
      this.dataStockist.nama_stockist = stockist.attributes.name;
      this.dataStockist.kode_stockist = stockist.attributes.code;
      this.dataStockist.phone = stockist.attributes.phone;
      this.dataStockist.email = stockist.attributes.email;
      this.dataStockist.alamat = stockist.attributes.address;
      this.dataStockist.is_suspended = !stockist.attributes.is_suspended;
      this.isLoading = true;
      const response = await ApiService.get(
        `api/v1/offices/${stockist.id}/users`
      );
      this.isLoading = false;
      this.dataStockist.users = response.data;
      this.dataStockist.area = this.getArea(
        stockist.relationships.area.data.id
      );

      // handle error when office doenst have relationship to address
      if (stockist.relationships.addresses.data.length == 0) {
        this.openDetail = !this.openDetail;
        return;
      }
      const address = this.getAddresses(
        stockist.relationships.addresses.data[0].id
      );
      this.dataStockist.provinsi.id = address.attributes.province_id;
      this.dataStockist.provinsi.name = address.attributes.province_name;
      this.dataStockist.kota.id = address.attributes.city_id;
      this.dataStockist.kota.name = address.attributes.city_name;
      this.dataStockist.kecamatan.id = address.attributes.district_id;
      this.dataStockist.kecamatan.name = address.attributes.district_name;
      this.dataStockist.desa.id = address.attributes.village_id;
      this.dataStockist.desa.name = address.attributes.village_name;
      this.openDetail = !this.openDetail;
    },
    changeStockist(data) {
      this.dataStockist.kode_stockist = data.attributes.code;
      this.searchStockist();
      this.searchStockistDetail = '';
      this.openListStockist = !this.openListStockist;
    },
    changeProvince() {
      this.fetchCities({
        province_id: this.dataStockist.provinsi.id,
        pageNumber: 1,
      });
      this.dataStockist.kota =
        this.dataStockist.kecamatan =
        this.dataStockist.desa =
          {
            id: '',
            name: '',
          };
    },
    changeCity() {
      this.fetchDistricts({
        city_id: this.dataStockist.kota.id,
        pageNumber: 1,
      });
      this.dataStockist.kecamatan = this.dataStockist.desa = {
        id: '',
        name: '',
      };
    },
    changeDistrict() {
      this.fetchVillages({
        district_id: this.dataStockist.kecamatan.id,
        pageNumber: 1,
      });
      this.dataStockist.desa = {
        id: '',
        name: '',
      };
    },
    getAddresses(id) {
      let address = this.getStockists.included.filter(function (el) {
        return el.id === id;
      });
      return address[0];
    },
    getArea(areaId) {
      return this.getRelationships(this.getStockists, areaId);
    },
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    searchStockist() {
      if (this.dataStockist.kode_stockist) {
        this.fetchStockists({
          keyword: this.dataStockist.kode_stockist,
        }).then(() => {
          if (this.getStockists.data.length > 0) {
            this.anyStockist = true;
            this.dataStockist.id = this.getStockists.data[0].id;
            this.dataStockist.kode_stockist =
              this.getStockists.data[0].attributes.code;
            this.dataStockist.nama_stockist =
              this.getStockists.data[0].attributes.name;
            this.dataStockist.phone =
              this.getStockists.data[0].attributes.phone;
            this.dataStockist.email =
              this.getStockists.data[0].attributes.email;
            this.dataStockist.alamat =
              this.getStockists.data[0].attributes.address;
            const addresses = this.getAddresses(
              this.getStockists.data[0].relationships.addresses.data[0].id
            );
            this.dataStockist.provinsi.id = addresses.attributes.province_id;
            this.dataStockist.provinsi.name =
              addresses.attributes.province_name;
            this.changeProvince();
            this.dataStockist.kota.id = addresses.attributes.city_id;
            this.dataStockist.kota.name = addresses.attributes.city_name;
            this.changeCity();
            this.dataStockist.kecamatan.id = addresses.attributes.district_id;
            this.dataStockist.kecamatan.name =
              addresses.attributes.district_name;
            this.changeDistrict();
            this.dataStockist.desa.id = addresses.attributes.village_id;
            this.dataStockist.desa.name = addresses.attributes.village_name;
          } else {
            this.anyStockist = false;
            this.dataStockist = {
              id: '',
              kode_stockist: this.dataStockist.kode_stockist,
              nama_stockist: '',
              phone: '',
              email: '',
              alamat: '',
              provinsi: {
                id: '',
                name: '',
              },
              kota: {
                id: '',
                name: '',
              },
              kecamatan: {
                id: '',
                name: '',
              },
              desa: {
                id: '',
                name: '',
              },
              area: null,
            };
          }
        });
      }
    },
    addStockist: function () {
      const payload = {
        data: {
          type: 'offices',
          attributes: {
            office_type: 'stockist',
            code: this.dataStockist.kode_stockist,
            name: this.dataStockist.nama_stockist,
            pasif: null,
            urut: null,
            address: this.dataStockist.alamat,
            address2: null,
            area: this.dataStockist.kota.area + '',
            kta: null,
            phone: this.dataStockist.phone,
            email: this.dataStockist.email,
            pulau: null,
            address_details: [
              {
                province_id: parseInt(this.dataStockist.provinsi.id),
                city_id: parseInt(this.dataStockist.kota.id),
                district_id: parseInt(this.dataStockist.kecamatan.id),
                village_id: parseInt(this.dataStockist.desa.id),
              },
            ],
          },
          relationships: {
            'office-category': {
              data: {
                type: 'office-categories',
                id: this.stockist_office_category[0].id,
              },
            },
          },
        },
      };
      if (!this.anyStockist) {
        this.createStockist(payload).then((response) => {
          if (response) {
            this.success = true;
            this.fetchStockists({
              pageNumber: 1,
              pageSize: 5,
            }).then((response) => {
              this.listStockist = response.data;
            });
          } else {
            this.failed = true;
          }
        });
      } else {
        payload.data.id = this.dataStockist.id;
        this.updateStockist(payload).then((response) => {
          if (response) {
            this.fetchStockists({
              pageNumber: 1,
              pageSize: 5,
            }).then((response) => {
              this.listStockist = response.data;
            });
            const payloadAddress = {
              data: {
                type: 'addresses',
                id: response.data.data.relationships['addresses'].data[0].id,
                attributes: {
                  detail: 'test',
                },
                relationships: {
                  office: {
                    data: {
                      type: 'offices',
                      id: this.dataStockist.id,
                    },
                  },
                  province: {
                    data: {
                      type: 'provinces',
                      id: this.dataStockist.provinsi.id,
                    },
                  },
                  city: {
                    data: {
                      type: 'cities',
                      id: this.dataStockist.kota.id,
                    },
                  },
                  district: {
                    data: {
                      type: 'districts',
                      id: this.dataStockist.kecamatan.id,
                    },
                  },
                  village: {
                    data: {
                      type: 'villages',
                      id: this.dataStockist.desa.id,
                    },
                  },
                },
              },
            };
            this.updateAddress(payloadAddress).then((response) => {
              if (response) {
                this.success = true;
              }
            });
          } else {
            this.failed = true;
          }
        });
      }
    },
    clearDataStockist() {
      this.dataStockist = {
        kode_stockist: '',
        nama_stockist: '',
        phone: '',
        email: '',
        alamat: '',
        provinsi: {
          id: '',
          name: '',
        },
        kota: {
          id: '',
          name: '',
        },
        kecamatan: {
          id: '',
          name: '',
        },
        desa: {
          id: '',
          name: '',
        },
        area: null,
      };
    },
    promoteStockistToSC() {
      const payload = {
        data: {
          type: 'offices',
          attributes: {
            code: this.promote.code,
            email: this.promote.email,
            no_account_gl: this.promote.no_account_gl,
            cost_center: this.promote.cost_center,
          },
        },
      };
      this.promoteToSC({
        payload,
        id: this.selectedOffice.id,
      }).then((response) => {
        if (response) {
          this.modalType = null;
          this.fetchOffices({
            pageNumber: 1,
            pageSize: 5,
            office_type: this.office_type,
          });
        } else {
          this.modal = 'error';
        }
      });
    },
    onActiveChange() {
      const payload = {
        data: {
          type: 'offices',
          id: this.dataStockist.id,
          attributes: {
            is_suspended: !this.dataStockist.is_suspended,
          },
        },
      };
      this.updateOffice(payload).then(() => {
        this.fetchStockists({
          pageNumber: 1,
          pageSize: 5,
        }).then((response) => {
          this.listStockist = response.data;
        });
      });
    },
    openPromote(data, type) {
      this.modalType = type;
      this.isModalOpen = true;
      this.selectedOffice = data;
    },
  },
};
</script>
