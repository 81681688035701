<template>
  <div>
    <base-select-search
      :is-loading="getLoading"
      :data="getStockists.data ?? []"
      label="code"
      :placeholder="placeholder"
      v-model="selected"
      v-on:infinite-scroll="handleInfiniteScroll"
      v-on:search="handleSearch"
      v-on:change="handleChange"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BaseSelectSearch from '@/components/base/BaseSelectSearch.vue';

export default {
  name: 'OfficeSelectSearch',
  components: { BaseSelectSearch },
  props: {
    value: null,
    limit: {
      type: Number,
      default: 10,
    },
    placeholder: {
      type: String,
      default: 'Cari Kode Stockist',
    },
  },
  emits: ['input', 'change'],
  data: function () {
    return {
      filter: {
        keyword: null,
        limit: 10,
      },
    };
  },
  computed: {
    ...mapGetters({
      getStockists: 'stockists/getStockists',
      getLoading: 'stockists/getLoading',
    }),
    selected: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    ...mapActions({
      fetchStockists: 'stockists/fetchStockists',
    }),
    init: function () {
      this.filter.limit = this.limit;
      // this.filter.sort = '-createdAt';

      this.loadOffices();
    },
    loadOffices: async function () {
      try {
        await this.fetchStockists({
          ...this.filter,
          pageSize: this.filter.limit,
          include: 'warehouses',
          'fields[offices]': 'code,name,warehouses',
          'fields[warehouses]': 'code,name',
        });
      } catch (err) {
        //
      }
    },
    handleInfiniteScroll: function () {
      if (this.filter.limit < this.getStockists.meta.page.total) {
        this.filter.limit += this.limit;

        this.loadOffices();
      }
    },
    handleSearch: function (search) {
      this.filter.limit = this.limit;
      this.filter.keyword = search;

      this.loadOffices();
    },
    handleChange: function () {
      this.$emit('change');
    },
  },
  created: function () {
    this.init();
  },
};
</script>
