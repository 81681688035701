<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg py-4">
      <div class="flex flex-col">
        <div class="flex items-center justify-between space-x-4">
          <div class="mt-1 flex space-x-4 rounded-md shadow-sm"></div>
          <base-search
            v-model="search"
            @input="debounceSearch"
            placeholder="Cari kode atau nama"
          />
        </div>
        <div class="my-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <MitraUsahaTable
              @click-row="officeDetail"
              @page-change="onPageChangeStockist"
            />
          </div>
        </div>
      </div>
    </div>
    <Modal
      :showing="openDetail"
      @close="openDetail = false"
      :showClose="openDetail"
      :backgroundClose="true"
      size="max-w-6xl"
    >
      <div class="flex justify-between px-4 py-5 sm:px-6">
        <div>
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Detail Kantor
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Berdasarkan data yang telah dibuat
          </p>
        </div>
        <div>
          <img src="@/assets/images/logo.png" alt="" class="w-52" />
        </div>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <div class="my-4 flex items-center justify-between">
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Biodata Mitra Usaha
          </h3>
          <toggle-button
            v-model="officeData.is_suspended"
            :labels="{
              checked: 'Aktif',
              unchecked: 'Non Aktif',
            }"
            :width="officeData.is_suspended ? 60 : 80"
            v-on:change="onActiveChange"
          />
        </div>
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6 sm:pb-6">
          <div class="sm:col-span-2">
            <dt
              v-if="officeData.office_type === 'member'"
              class="text-sm font-bold text-gray-700"
            >
              Kode Tetap / Sementara Mitra Usaha
            </dt>
            <dt v-else class="text-sm font-bold text-gray-700">Kode Kantor</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ getOffice.data?.attributes.code }} /
              {{ getOffice.data?.attributes.temp_code }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Nama Mitra Usaha</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ officeData.name }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">No. KTP</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ officeData.ktp_number }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Tanggal lahir</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{
                officeData.birthdate
                  ? dayjs(officeData.birthdate).format('L')
                  : 'Tanggal lahir belum diatur'
              }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">No. Hp</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ officeData.phone || 'Tidak ada No. HP' }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Email</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ officeData.email || 'Tidak ada Email' }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Alamat Mitra Usaha</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ officeData.address || 'Alamat belum diatur' }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Pendidikan Terakhir</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{
                getRelationships(
                  getOffice,
                  getOffice.data?.relationships?.education?.data?.id
                )?.attributes.name || '-'
              }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Pekerjaan Terakhir</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{
                getRelationships(
                  getOffice,
                  getOffice.data?.relationships.profession.data?.id
                )?.attributes.name || '-'
              }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Dibuat</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ officeData.attributes?.createdAt | formatDate }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Terakhir Diperbarui</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ officeData.attributes?.updatedAt | formatDate }}
            </dd>
          </div>

          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">No Uplines</dt>
            <dd
              v-if="getOffice.data?.relationships.uplines?.data?.length > 0"
              class="mt-1 text-sm text-gray-900"
            >
              <template
                v-for="rel in getOffice.data?.relationships.uplines?.data"
              >
                <BaseBadge :key="rel.id">
                  {{ getRelationships(getOffice, rel.id).attributes.code }}
                </BaseBadge>
              </template>
            </dd>
            <span v-else> - </span>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Nama Uplines</dt>
            <dd
              v-if="getOffice.data?.relationships.uplines?.data?.length > 0"
              class="mt-1 text-sm text-gray-900"
            >
              <template
                v-for="rel in getOffice.data?.relationships.uplines?.data"
              >
                <BaseBadge :key="rel.id">
                  {{ getRelationships(getOffice, rel.id).attributes.name }}
                </BaseBadge>
              </template>
            </dd>
            <span v-else> - </span>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Jenis Kelamin</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <BaseBadge v-if="getOffice.data?.attributes.sex">
                {{
                  getOffice.data?.attributes.sex == 'male'
                    ? 'Laki-laki'
                    : getOffice.data?.attributes.sex == 'female'
                    ? 'Perempuan'
                    : getOffice.data?.attributes.sex
                }}
              </BaseBadge>
              <span v-else>-</span>
            </dd>
          </div>

          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Kode Pos</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <BaseBadge v-if="getOffice.data?.attributes.postal_code">
                {{ getOffice.data?.attributes.postal_code }}
              </BaseBadge>
              <span v-else>-</span>
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Area</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <BaseBadge v-if="getOffice.data?.relationships.area.data?.id">
                {{
                  getRelationships(
                    getOffice,
                    getOffice.data?.relationships.area.data?.id
                  ).attributes.code
                }}
              </BaseBadge>
              <span v-else>-</span>
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Nama Rekening</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <BaseBadge v-if="getOffice.data?.attributes.bank_account_name">
                {{ getOffice.data.attributes.bank_account_name }}
              </BaseBadge>
              <span v-else>-</span>
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">No. Rekening</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <BaseBadge v-if="getOffice.data?.attributes.bank_account_number">
                {{ getOffice.data.attributes.bank_account_number }}
              </BaseBadge>
              <span v-else>-</span>
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Nama Bank</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <BaseBadge v-if="getOffice.data?.attributes.bank_name">
                {{ getOffice.data.attributes.bank_name }}
              </BaseBadge>
              <span v-else>-</span>
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Cabang Bank</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <BaseBadge v-if="getOffice.data?.attributes.bank_branch_name">
                {{ getOffice.data.attributes.bank_branch_name }}
              </BaseBadge>
              <span v-else>-</span>
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Nama NPWP</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <BaseBadge v-if="getOffice.data?.attributes.npwp_name">
                {{ getOffice.data.attributes.npwp_name }}
              </BaseBadge>
              <span v-else>-</span>
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">No NPWP</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <BaseBadge v-if="getOffice.data?.attributes.npwp_number">
                {{ getOffice.data.attributes.npwp_number }}
              </BaseBadge>
              <span v-else>-</span>
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">No Akun GL</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <BaseBadge v-if="getOffice.data?.attributes.no_account_gl">
                {{ getOffice.data.attributes.no_account_gl }}
              </BaseBadge>
              <span v-else>-</span>
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Status Pernikahan</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <BaseBadge v-if="getOffice.data?.attributes.marital_status">
                {{ getOffice.data.attributes.marital_status }}
              </BaseBadge>
              <span v-else>-</span>
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">
              Nama / Tanggal Lahir Pasangan
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ getOffice.data?.attributes.spouse_name ?? '' }}
              {{
                getOffice.data?.attributes.spouse_birthdate
                  ? dayjs(getOffice.data?.attributes.spouse_birthdate).format(
                      'L'
                    )
                  : ''
              }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">
              Nama / Tanggal Lahir Pewaris
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ getOffice.data?.attributes.devisor_name ?? '' }}
              {{
                getOffice.data?.attributes.devisor_birthdate
                  ? dayjs(getOffice.data?.attributes.devisor_birthdate).format(
                      'L'
                    )
                  : ''
              }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Wilayah Harga</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{
                getRelationships(
                  getOffice,
                  getOffice.data?.relationships.area.data?.id
                )?.attributes.name
              }}
              ({{
                getRelationships(
                  getOffice,
                  getOffice.data?.relationships.area.data?.id
                )?.attributes.code
              }})
            </dd>
          </div>
        </dl>
      </div>
      <section class="px-6">
        <h3 class="mb-4 text-lg font-bold leading-6 text-gray-900">
          Daftar User di Kantor Ini
        </h3>
        <Datatable
          :total="officeData.users.meta.page.total"
          :perPage="officeData.users.meta.page.perPage"
          :currentPage="officeData.users.meta.page.currentPage"
          :paginated="false"
        >
          <template v-slot:thead>
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Nama
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Email
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Role
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Status
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody v-if="officeData.users.data.length > 0">
              <tr
                class="bg-white hover:bg-green-100"
                v-for="(data, dataIdx) in officeData.users.data"
                :key="data.id"
                :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
              >
                <td
                  class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                >
                  {{ data.attributes.name }}
                </td>
                <td
                  class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                >
                  {{ data.attributes.email }}
                </td>
                <td
                  class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                >
                  {{ data.attributes.current_role }}
                </td>
                <td
                  class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                >
                  <toggle-button
                    :value="!data.attributes.is_suspended"
                    :labels="{
                      checked: 'Aktif',
                      unchecked: 'Non Aktif',
                    }"
                    :width="data.attributes.is_suspended ? 60 : 80"
                    v-on:change="onActiveUserChange(data)"
                  />
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr class="bg-white">
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                  colspan="4"
                >
                  Data tidak ditemukan
                </td>
              </tr>
            </tbody>
          </template>
        </Datatable>
      </section>
      <div class="mx-8 flex justify-end space-x-4">
        <!-- <button
            @click="openEditPassword = !openEditPassword"
            type="button"
            class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm"
          >
            Ubah Kata Sandi
          </button> -->
        <button
          @click="openDetail = !openDetail"
          type="button"
          class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
        >
          Tutup
        </button>
      </div>
    </Modal>

    <transition
      name="customFade"
      enter-active-class="fadeIn"
      leave-active-class="fadeOut"
    >
      <div
        v-if="openEditPassword"
        style="animation-duration: 0.3s"
        class="fixed inset-0 z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="mx-4 flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
          >
            <form @submit.prevent="onUpdatePassword">
              <div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3
                    class="text-lg font-medium leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Ubah Kata Sandi
                  </h3>
                </div>
                <div class="my-4 space-y-3">
                  <div class="field-inset-default w-full">
                    <label
                      for="password"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Kata sandi baru
                    </label>
                    <input
                      v-model="editPasswordForm.password"
                      type="password"
                      name="password"
                      id="password"
                      class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Kata sandi baru"
                    />
                  </div>
                  <div class="field-inset-default w-full">
                    <label
                      for="password_confirmation"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Konfirmasi kata sandi baru
                    </label>
                    <input
                      v-model="editPasswordForm.password_confirmation"
                      type="password"
                      name="password_confirmation"
                      id="password_confirmation"
                      class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Konfirmasi kata sandi baru"
                    />
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
                <button
                  type="submit"
                  class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                >
                  Simpan
                </button>
                <button
                  @click="openEditPassword = !openEditPassword"
                  type="button"
                  class="focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                >
                  Tutup
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="modal === 'success'"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
          >
            <div>
              <div
                class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
              >
                <svg
                  class="h-6 w-6 text-green-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <slot name="heading"> Data Berhasil disimpan </slot>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <div class="flex items-center gap-2">
                <button
                  @click="modal = null"
                  class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                >
                  Selesai
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="modal === 'error'"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
          >
            <div>
              <div
                class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-red-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <slot name="heading">
                  {{ getError }}
                </slot>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <div class="flex items-center gap-2">
                <button
                  @click="modal = null"
                  class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                >
                  Selesai
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- MODAL VALIDASI KEMASAN-->
    <Modal
      :showing="modal === 'promoteToStockist'"
      @close="modal = null0"
      :showClose="modal === 'promoteToStockist'"
      :backgroundClose="true"
      size="max-w-xl"
    >
      <form @submit.prevent="promoteMemberToStockist">
        <div>
          <div class="mt-3 text-center sm:mt-5">
            <h3
              class="text-lg font-medium leading-6 text-gray-900"
              id="modal-title"
            >
              Promosi Kantor
            </h3>
            <div class="mt-2">
              <p class="text-sm text-gray-500">
                Lengkapi form dibawah ini untuk melakukan promosi
              </p>
            </div>
          </div>
          <div class="my-4 space-y-3">
            <div class="space-y-3">
              <div class="field-inset-default w-full">
                <label for="code" class="block text-xs font-bold text-gray-700">
                  Kode Kantor
                </label>
                <input
                  v-model="promote.code"
                  type="text"
                  name="code"
                  id="code"
                  class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan kode kantor (kode kantor harus berbeda)"
                />
              </div>
              <div class="field-inset-default w-full">
                <label
                  for="email"
                  class="block text-xs font-bold text-gray-700"
                >
                  Email
                </label>
                <input
                  v-model="promote.email"
                  type="email"
                  name="email"
                  id="email"
                  class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan email baru (contoh: memberbaru@naturalnusantara.co.id)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
          <button
            type="submit"
            class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
          >
            Promosi
          </button>
          <button
            @click="modal = null"
            type="button"
            class="focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
          >
            Tutup
          </button>
        </div>
      </form>
    </Modal>

    <transition
      name="customFade"
      enter-active-class="fadeIn"
      leave-active-class="fadeOut"
    >
      <div
        v-if="modal === 'promoteToSC'"
        style="animation-duration: 0.3s"
        class="fixed inset-0 z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="mx-4 flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
          >
            <form @submit.prevent="promoteStockistToSC">
              <div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3
                    class="text-lg font-medium leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Promosi Kantor
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      Lengkapi form dibawah ini untuk melakukan promosi
                    </p>
                  </div>
                </div>
                <div class="my-4 space-y-3">
                  <div class="space-y-3">
                    <div class="field-inset-default w-full">
                      <label
                        for="code"
                        class="block text-xs font-bold text-gray-700"
                      >
                        Kode Kantor
                      </label>
                      <input
                        v-model="promote.code"
                        type="text"
                        name="code"
                        id="code"
                        class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        placeholder="Masukkan kode kantor (kode kantor harus berbeda)"
                      />
                    </div>
                    <div class="field-inset-default w-full">
                      <label
                        for="email"
                        class="block text-xs font-bold text-gray-700"
                      >
                        Email
                      </label>
                      <input
                        v-model="promote.email"
                        type="email"
                        name="email"
                        id="email"
                        class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        placeholder="Masukkan email baru (contoh: memberbaru@naturalnusantara.co.id)"
                      />
                    </div>
                    <div class="field-inset-default w-full">
                      <label
                        for="no_account_gl"
                        class="block text-xs font-bold text-gray-700"
                      >
                        No Account GL
                      </label>
                      <input
                        v-model="promote.no_account_gl"
                        type="text"
                        name="no_account_gl"
                        id="no_account_gl"
                        class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        placeholder="Masukkan No Account GL baru (contoh: 2342ABFDS"
                      />
                    </div>
                    <div class="field-inset-default w-full">
                      <label
                        for="cost_center"
                        class="block text-xs font-bold text-gray-700"
                      >
                        Cost Center
                      </label>
                      <input
                        v-model="promote.cost_center"
                        type="text"
                        name="cost_center"
                        id="cost_center"
                        class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        placeholder="Masukkan No Cost Center (wajib)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
                <button
                  type="submit"
                  class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                >
                  Promosi
                </button>
                <button
                  @click="modal = null"
                  type="button"
                  class="focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                >
                  Tutup
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </transition>
    <loading v-if="getLoading || isLoading" />
  </div>
</template>

<script>
// import { mapGetters, mapActions} from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import { mapActions, mapGetters } from 'vuex';
import { ToggleButton } from 'vue-js-toggle-button';
import Modal from '@/components/base/BaseModal';
import dayjs from 'dayjs';
import ApiService from '@/services/api.service';
import BaseBadge from '@/components/base/BaseBadge.vue';
import BaseSearch from '@/components/base/Search.vue';
import MitraUsahaTable from '../../../../components/mitra-usaha/mitra-usaha-table.vue';

export default {
  name: 'Home',
  components: {
    Datatable,
    ToggleButton,
    Modal,
    BaseBadge,
    BaseSearch,
    MitraUsahaTable,
  },
  data() {
    return {
      search: null,
      isLoading: false,
      office_type: 'member',
      modal: null,
      officeSelected: null,
      promote: {
        code: null,
        email: null,
        no_account_gl: null,
        cost_center: null,
      },
      openDetail: false,
      openEditPassword: false,
      officeData: {
        id: '',
        office_type: '',
        code: '',
        deposit_balance: '',
        deposit_multiplier: '',
        name: '',
        ktp_number: '',
        birthdate: '',
        address: '',
        address2: '',
        phone: '',
        marital_status: '',
        spouse_name: '',
        spouse_birthdate: '',
        devisor_name: '',
        devisor_birthdate: '',
        bank_account_number: '',
        bank_account_name: '',
        bank_name: '',
        bank_branch_name: '',
        npwp_number: '',
        npwp_name: '',
        npwp_address: '',
        npwp_address2: '',
        users: {
          data: [],
          meta: {
            page: {
              total: '',
              perPage: '',
              currentPage: '',
            },
          },
        },
        address_details: [
          {
            office: '',
            province: '',
            city: '',
            district: '',
            village: '',
            detail: '',
          },
        ],
        is_suspended: '',
        is_promoted: '',
      },
      editPasswordForm: {
        password: '',
        password_confirmation: '',
      },
    };
  },
  created() {
    this.loadMember();

    const handleEscape = () => {
      this.openDetail =
        this.openListStockist =
        this.success =
        this.failed =
        this.openAdd =
          false;
      this.search = '';
    };
    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      getOffices: 'offices/getOffices',
      getOffice: 'offices/getOffice',
      getLoading: 'offices/getLoading',
      getError: 'offices/getError',
    }),
    officeParams() {
      return {
        include: '',
        fields: {
          offices:
            'address,name,code,bank_account_number,phone,office_type,is_promoted,promoted_by,area',
        },
      };
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOffices: 'offices/fetchOffices',
      fetchOfficeById: 'offices/fetchOfficeById',
      updateOffice: 'offices/updateOffice',
      promoteToStockist: 'offices/promoteToStockist',
      promoteToSC: 'offices/promoteToSC',
      updateUser: 'users/updateUser',
      fetchSimpleOffices: 'simple_offices/fetchSimpleOffices',
    }),
    async loadMember(params = {}) {
      return await this.fetchSimpleOffices({
        ...params,
        'filter[search]': this.search,
        'filter[office_category_id]': 5,
        'page[limit]': 5,
        include: 'office',
        'fields[simple-offices]': 'code,name,office,office_id',
        'fields[offices]':
          'phone,is_promoted,address,email,bank_account_number,promoted_by,upline_histories,downline_histories',
      });
    },
    debounceSearch: debounce(function () {
      this.loadMember();
    }, 300),
    onPageChangeStockist(page) {
      this.loadMember(page);
    },
    openPromote(data, type) {
      this.modal = type;
      this.officeSelected = data;
    },
    promoteMemberToStockist() {
      const payload = {
        data: {
          type: 'offices',
          attributes: {
            code: this.promote.code,
            email: this.promote.email,
          },
        },
      };
      this.promoteToStockist({
        payload,
        id: this.officeSelected.id,
      }).then((response) => {
        if (response) {
          this.modal = 'success';
          this.loadMember();
        } else {
          this.modal = 'error';
        }
      });
    },
    promoteStockistToSC() {
      const payload = {
        data: {
          type: 'offices',
          attributes: {
            code: this.promote.code,
            email: this.promote.email,
            no_account_gl: this.promote.no_account_gl,
            cost_center: this.promote.cost_center,
          },
        },
      };
      this.promoteToSC({
        payload,
        id: this.officeSelected.id,
      }).then((response) => {
        if (response) {
          this.modal = 'success';
          this.loadMember();
        } else {
          this.modal = 'error';
        }
      });
    },
    async officeDetail(selectedOffice) {
      this.isLoading = true;
      ApiService.get(
        `api/v1/offices/${selectedOffice.attributes.office_id}/users`
      ).then((response) => {
        this.officeData.users = response.data;
        this.isLoading = false;
        this.openDetail = !this.openDetail;
      });
      const { data } = await this.fetchOfficeById({
        office_id: selectedOffice.attributes.office_id,
        params: {
          include: 'education,profession,area',
        },
      });
      const office = data.data;
      (this.officeData.id = office.id),
        (this.officeData.office_type = office.attributes.office_type),
        (this.officeData.code = office.attributes.code),
        (this.officeData.deposit_balance = office.attributes.deposit_balance),
        (this.officeData.deposit_multiplier =
          office.attributes.deposit_multiplier),
        (this.officeData.name = office.attributes.name),
        (this.officeData.ktp_number = office.attributes.ktp_number),
        (this.officeData.birthdate = office.attributes.birthdate),
        (this.officeData.address = office.attributes.address),
        (this.officeData.address2 = office.attributes.address2),
        (this.officeData.phone = office.attributes.phone),
        (this.officeData.marital_status = office.attributes.marital_status),
        (this.officeData.spouse_name = office.attributes.spouse_name),
        (this.officeData.spouse_birthdate = office.attributes.spouse_birthdate),
        (this.officeData.devisor_name = office.attributes.devisor_name),
        (this.officeData.devisor_birthdate =
          office.attributes.devisor_birthdate),
        (this.officeData.bank_account_number =
          office.attributes.bank_account_number),
        (this.officeData.bank_account_name =
          office.attributes.bank_account_name),
        (this.officeData.bank_name = office.attributes.bank_name),
        (this.officeData.bank_branch_name = office.attributes.bank_branch_name),
        (this.officeData.npwp_number = office.attributes.npwp_number),
        (this.officeData.npwp_name = office.attributes.npwp_name),
        (this.officeData.npwp_address = office.attributes.npwp_address),
        (this.officeData.npwp_address2 = office.attributes.npwp_address2),
        (this.officeData.address_details = [
          {
            office: office.attributes.office,
            province: office.attributes.province,
            city: office.attributes.city,
            district: office.attributes.district,
            village: office.attributes.village,
            detail: office.attributes.detail,
          },
        ]);
    },
    onActiveChange() {
      const payload = {
        data: {
          type: 'offices',
          id: this.officeData.id,
          attributes: {
            is_suspended: !this.officeData.is_suspended,
          },
        },
      };
      this.updateOffice(payload).then((response) => {
        this.loadMember();
        if (response) {
          this.success = true;
          this.loadMember();
        } else {
          this.failed = true;
        }
      });
    },
    onUpdatePassword() {
      const payload = {
        data: {
          type: 'users',
          id: this.officeData.id,
          attributes: {
            password: this.editPasswordForm.password,
            password_confirmation: this.editPasswordForm.password_confirmation,
          },
        },
      };
      this.updateUser(payload).then((response) => {
        if (response) {
          this.success = true;
          this.openEditPassword = false;
        } else {
          this.failed = true;
        }
      });
    },
    onActiveUserChange(user) {
      const payload = {
        data: {
          type: 'users',
          id: user.id,
          attributes: {
            is_suspended: !user.attributes.is_suspended,
          },
        },
      };
      this.updateUser(payload);
    },
    getRelationships(resource, id) {
      return resource.included?.find((it) => it.id == id);
    },
    onPageChangeUser() {},
  },
};
</script>
