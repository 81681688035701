<template>
  <base-wrapper>
    <div class="space-y-4">
      <div class="flex items-center justify-end gap-x-2">
        <mitra-period-month-select
          :shadow="false"
          v-model="filter.periodId"
          @change="onFilter"
        />
        <base-input
          :shadow="false"
          placeholder="Cari Kode atau Nama"
          type="search"
          debounce
          label="Cari"
          v-on:native-input="onFilter"
          v-model="filter.search"
        />
      </div>
      <datatable
        :columns="columns"
        :scroll-x="false"
        :total="offices.meta.page.total"
        :perPage="offices.meta.page.perPage"
        :currentPage="offices.meta.page.currentPage"
        :meta="offices.meta"
        cursor
        @pagechanged="onPageChanged"
      >
        <template v-slot:tbody="{ classes }">
          <tr
            v-for="office in offices.data"
            :key="office.id"
            :class="[classes.tr, 'bg-white hover:bg-green-100']"
          >
            <td :class="[classes.td]">
              <span class="font-bold text-gray-900">
                {{ office.attributes.code }} /
                {{ office.attributes.temp_code }}
              </span>
            </td>
            <td :class="[classes.td]">
              {{ office.attributes.name || '-' }}
            </td>
            <td :class="[classes.td]">
              {{ getOffice(office).attributes.phone || '-' }}
            </td>
            <td :class="[classes.td]">
              {{ getOffice(office).attributes.bank_account_number || '-' }}
            </td>
            <td :class="[classes.td]">
              {{ getUpline(office, 'referred_by')?.code || '-' }}
            </td>
            <td :class="[classes.td]">
              {{ getUpline(office, 'referred_by')?.name || '-' }}
            </td>
            <td :class="[classes.td]">
              {{
                getOffice(office).attributes.is_kta_need_delivery
                  ? 'Diambil'
                  : 'Dikirim'
              }}
            </td>
            <td :class="[classes.td]">
              <span v-if="getOffice(office).attributes.bonus_month">
                {{ getOffice(office).attributes.bonus_month | format('MMMM') }}
              </span>
              <span v-else>-</span>
            </td>
          </tr>
        </template>
      </datatable>
    </div>

    <loading v-if="loading" />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import MitraPeriodMonthSelect from '@/components/period/mitra-period/mitra-period-month-select.vue';

export default {
  mixins: [requestMixin],
  components: {
    MitraPeriodMonthSelect,
  },
  data() {
    return {
      loading: false,
      offices: {
        data: [],
        meta: {
          page: {},
        },
      },
      filter: {
        search: null,
        periodId: null,
      },
    };
  },
  computed: {
    columns() {
      return [
        { id: 'code', name: 'Nomor Mitra Usaha' },
        { id: 'name', name: 'Nama Mitra Usaha' },
        { id: 'phone', name: 'No. HP' },
        { id: 'bank_account_number', name: 'No. Rek	' },
        { id: 'upline_code', name: 'No. Upline' },
        { id: 'upline_name', name: 'Nama Upline' },
        { id: 'is_kta_need_delivery', name: 'KTA' },
        { id: 'bonus_month', name: 'Bulan Bonus' },
      ];
    },
  },
  methods: {
    onPageChanged(page) {
      this.loadOffices({
        ...page,
      });
    },
    onFilter() {
      this.loadOffices();
    },
    getOffice(office) {
      return this.getSingleIncludedType('office', this.offices, office);
    },
    getUpline(office, type) {
      const uplines = this.getSingleIncludedType('office', this.offices, office)
        .attributes.upline_histories;

      if (!uplines || !uplines.length) {
        return null;
      }

      return uplines.find((upline) => upline.type === type);
    },
    async loadOffices(params) {
      this.loading = true;

      const [res, error] = await this.request('/api/v1/offices', {
        params: {
          'page[limit]': 5,
          'filter[search]': this.filter.search,
          'filter[period_uuid]': this.filter.periodId,
          'fields[simple-offices]': 'code,temp_code,name,office_id,office',
          'fields[offices]': 'name,phone,upline_histories',
          'filter[office_category_id]': 5,
          include: 'office',
          ...params,
        },
      });

      if (!error) {
        this.offices = res;
      }

      this.loading = false;
    },
  },
  created() {
    this.loadOffices();
  },
};
</script>
