<template>
  <base-input label="Pilih Periode" :disabled="disabled" with-label>
    <base-select
      :options="selectMonthOptions"
      :shadow="false"
      min-w-fit
      v-model="month"
      v-on:change="handleChangeMonth"
      :disabled="disabled"
    />
  </base-input>
</template>

<script>
import BaseInput from '@/components/base/BaseInput.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: { BaseSelect, BaseInput },
  props: {
    value: {
      type: String,
      default: null,
    },
    year: {
      type: Number,
      default: new Date().getFullYear(),
    },
    disabled: Boolean,
  },
  emits: ['input', 'change'],
  computed: {
    ...mapGetters({
      getMitraPeriods: 'mitra_periods/getMitraPeriods',
    }),
    month: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    selectMonthOptions() {
      return [
        {
          key: 'all',
          value: null,
          label: 'Semua',
        },
        ...this.getMitraPeriods.data.map((period) => ({
          key: period.attributes.name,
          value: period.id,
          label: period.attributes.name,
        })),
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchMitraPeriods: 'mitra_periods/fetchMitraPeriods',
    }),
    handleChangeMonth() {
      this.$emit('change');
    },
  },
  created() {
    this.fetchMitraPeriods({
      'filter[year]': this.year,
      'fields[mitra-periods]':
        'name,year,month,start_date,end_date,bonus_start_date,bonus_end_date',
    });
  },
};
</script>
