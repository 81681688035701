<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg py-4">
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <base-search
            v-model="search"
            @input="debounceSearch"
            placeholder="Cari kode atau nama"
          />
          <div>
            <button
              @click="downloadProduct"
              v-if="!isWaitForDownload"
              class="focus:outline-none ml-2 inline-flex items-center gap-2 rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              <!-- Heroicon name: solid/mail -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                />
              </svg>
            </button>
            <button
              v-else
              class="focus:outline-none ml-2 inline-flex items-center gap-2 rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              <div role="status">
                <svg
                  aria-hidden="true"
                  class="fill-blue-600 w-5 animate-spin text-gray-200 dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </div>
        </div>
        <div class="my-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="getProducts.meta.page.total"
              :perPage="getProducts.meta.page.perPage"
              :currentPage="getProducts.meta.page.currentPage"
              @pagechanged="onPageChangeProducts"
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      @click="sortData('code')"
                      scope="col"
                      class="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode Barang
                    </th>
                    <th
                      @click="sortData('name')"
                      scope="col"
                      class="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nama Barang
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode Supplier
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kelompok Produk
                    </th>
                    <!-- <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Aksi
                      </th> -->
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody>
                  <tr
                    class="cursor-pointer bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in getProducts.data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    @click="detailBarang(data)"
                  >
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                    >
                      {{ data.attributes.code }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.name }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.supplier_code }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{
                        data.attributes['product_category_name']
                          ? data.attributes['product_category_name']
                          : '-'
                      }}
                    </td>
                    <!-- <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-indigo-600"
                      >
                        <button type="button" @click="openModalDeleteData(data)">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5 text-red-400 hover:text-red-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </button>
                      </td> -->
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>
        <Modal
          :showing="openDetail"
          @close="openDetail = false"
          :showClose="openDetail"
          :backgroundClose="true"
          size="max-w-6xl"
        >
          <div class="flex justify-between px-4 py-5 sm:px-6">
            <div>
              <h3 class="text-lg font-bold leading-6 text-gray-900">
                Detail Barang
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Berdasarkan data barang yang telah dibuat
              </p>
            </div>
            <div>
              <img src="@/assets/images/logo.png" alt="" class="w-52" />
            </div>
          </div>
          <div
            class="border-t border-gray-200 px-4 py-5 sm:px-6"
            v-if="openDetail"
          >
            <toggle-button
              v-model="dataProduct.attributes.is_active"
              :labels="{
                checked: 'Aktif',
                unchecked: 'Non Aktif',
              }"
              :width="dataProduct.attributes.is_active ? 60 : 80"
              v-on:change="onActiveChange"
              class="mb-8"
            />
            <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6 sm:pb-6">
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">Kode Barang</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ dataProduct.attributes.code }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">Nama Barang</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ dataProduct.attributes.name }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">Kategori Barang</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ dataProduct.attributes.product_category_name }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">Kode Supplier</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ dataProduct.attributes.supplier_code }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">Nama Supplier</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ dataProduct.attributes.supplier_name }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">Harga Supplier</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ dataProduct.attributes.supplier_price | toCurrency }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">P/N</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{
                    !!dataProduct.attributes.is_point ? 'Point' : 'Non Point'
                  }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">PV</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ dataProduct.attributes.point }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">BV</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ dataProduct.attributes.bonus | toCurrency }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">Lembaga Ijin</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ dataProduct.attributes.license_institution }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">Status Izin</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  <span
                    v-if="dataProduct.attributes.license_status"
                    class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                  >
                    Lengkap
                  </span>
                  <span
                    v-else
                    class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800"
                  >
                    Sedang Proses
                  </span>
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">
                  Mulai Izin - Akhir Izin
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ dataProduct.attributes.license_start }}
                  <span class="px-1 font-light text-gray-300">s.d</span>
                  {{ dataProduct.attributes.license_end }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">Dibuat</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ dataProduct.attributes.createdAt | formatDate }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">
                  Terakhir Diperbarui
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ dataProduct.attributes.updatedAt | formatDate }}
                </dd>
              </div>
            </dl>
            <div class="mt-4 flex flex-col">
              <div class="my-2 -flex sm:-mx-6 lg:-mx-8">
                <div
                  class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
                >
                  <div
                    class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg"
                  >
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Area
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Harga Stockist
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Harga Mitra Usaha
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Harga Konsumen
                          </th>
                        </tr>
                      </thead>
                      <tbody class="divide-y divide-gray-200 bg-white">
                        <tr>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          >
                            {{
                              (dataProduct.harga_wil0_stockist =
                                this.getProductPrice(
                                  'Wilayah Jawa',
                                  'Stockist',
                                  dataProduct
                                ).attributes.area)
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{
                              (dataProduct.harga_wil0_stockist =
                                this.getProductPrice(
                                  'Wilayah Jawa',
                                  'Stockist',
                                  dataProduct
                                ).attributes.price) | toCurrency
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{
                              (dataProduct.harga_wil0_distributor =
                                this.getProductPrice(
                                  'Wilayah Jawa',
                                  'Mitra Usaha',
                                  dataProduct
                                ).attributes.price) | toCurrency
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{
                              (dataProduct.harga_wil0_konsumen =
                                this.getProductPrice(
                                  'Wilayah Jawa',
                                  'Konsumen',
                                  dataProduct
                                ).attributes.price) | toCurrency
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          >
                            {{
                              (dataProduct.harga_wil1_stockist =
                                this.getProductPrice(
                                  'Wilayah I',
                                  'Stockist',
                                  dataProduct
                                ).attributes.area)
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{
                              (dataProduct.harga_wil1_stockist =
                                this.getProductPrice(
                                  'Wilayah I',
                                  'Stockist',
                                  dataProduct
                                ).attributes.price) | toCurrency
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{
                              (dataProduct.harga_wil1_distributor =
                                this.getProductPrice(
                                  'Wilayah I',
                                  'Mitra Usaha',
                                  dataProduct
                                ).attributes.price) | toCurrency
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{
                              (dataProduct.harga_wil1_konsumen =
                                this.getProductPrice(
                                  'Wilayah I',
                                  'Konsumen',
                                  dataProduct
                                ).attributes.price) | toCurrency
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          >
                            {{
                              (dataProduct.harga_wil2_stockist =
                                this.getProductPrice(
                                  'Wilayah II',
                                  'Stockist',
                                  dataProduct
                                ).attributes.area)
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{
                              (dataProduct.harga_wil2_stockist =
                                this.getProductPrice(
                                  'Wilayah II',
                                  'Stockist',
                                  dataProduct
                                ).attributes.price) | toCurrency
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{
                              (dataProduct.harga_wil2_distributor =
                                this.getProductPrice(
                                  'Wilayah II',
                                  'Mitra Usaha',
                                  dataProduct
                                ).attributes.price) | toCurrency
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{
                              (dataProduct.harga_wil2_konsumen =
                                this.getProductPrice(
                                  'Wilayah II',
                                  'Konsumen',
                                  dataProduct
                                ).attributes.price) | toCurrency
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          >
                            {{
                              (dataProduct.harga_wil3_stockist =
                                this.getProductPrice(
                                  'Wilayah III',
                                  'Stockist',
                                  dataProduct
                                ).attributes.area)
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{
                              (dataProduct.harga_wil3_stockist =
                                this.getProductPrice(
                                  'Wilayah III',
                                  'Stockist',
                                  dataProduct
                                ).attributes.price) | toCurrency
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{
                              (dataProduct.harga_wil3_distributor =
                                this.getProductPrice(
                                  'Wilayah III',
                                  'Mitra Usaha',
                                  dataProduct
                                ).attributes.price) | toCurrency
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{
                              (dataProduct.harga_wil3_konsumen =
                                this.getProductPrice(
                                  'Wilayah III',
                                  'Konsumen',
                                  dataProduct
                                ).attributes.price) | toCurrency
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          >
                            {{
                              (dataProduct.harga_wil4_stockist =
                                this.getProductPrice(
                                  'Wilayah IV',
                                  'Stockist',
                                  dataProduct
                                ).attributes.area)
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{
                              (dataProduct.harga_wil4_stockist =
                                this.getProductPrice(
                                  'Wilayah IV',
                                  'Stockist',
                                  dataProduct
                                ).attributes.price) | toCurrency
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{
                              (dataProduct.harga_wil4_distributor =
                                this.getProductPrice(
                                  'Wilayah IV',
                                  'Mitra Usaha',
                                  dataProduct
                                ).attributes.price) | toCurrency
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{
                              (dataProduct.harga_wil4_konsumen =
                                this.getProductPrice(
                                  'Wilayah IV',
                                  'Konsumen',
                                  dataProduct
                                ).attributes.price) | toCurrency
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <dl
              class="mt-4 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 sm:pb-6"
            >
              <div
                class="flex items-center space-x-3 rounded-lg border border-gray-200 bg-white px-4 py-3 shadow-sm"
              >
                <div class="min-w-0 flex-1">
                  <dt class="text-sm font-bold text-gray-700">
                    Harga Karyawan
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{
                      (dataProduct.harga_wil0_stockist = this.getProductPrice(
                        'Wilayah Jawa',
                        'Karyawan',
                        dataProduct
                      ).attributes.price) | toCurrency
                    }}
                  </dd>
                </div>
              </div>
              <div
                class="flex items-center space-x-3 rounded-lg border border-gray-200 bg-white px-4 py-3 shadow-sm"
              >
                <div class="min-w-0 flex-1">
                  <dt class="text-sm font-bold text-gray-700">Harga Khusus</dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{
                      (dataProduct.harga_wil0_stockist = this.getProductPrice(
                        'Wilayah Jawa',
                        'Khusus',
                        dataProduct
                      ).attributes.price) | toCurrency
                    }}
                  </dd>
                </div>
              </div>
              <div
                class="flex items-center space-x-3 rounded-lg border border-gray-200 bg-white px-4 py-3 shadow-sm"
              >
                <div class="min-w-0 flex-1">
                  <dt class="text-sm font-bold text-gray-700">Free</dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{
                      (dataProduct.harga_wil0_stockist = this.getProductPrice(
                        'Wilayah Jawa',
                        'Free',
                        dataProduct
                      ).attributes.price) | toCurrency
                    }}
                  </dd>
                </div>
              </div>
            </dl>
          </div>
          <div class="flex justify-end gap-x-2">
            <base-button
              color="transparent"
              @click="openDetail = !openDetail"
              type="button"
            >
              Tutup
            </base-button>
          </div>
        </Modal>

        <transition name="fade">
          <div
            v-if="success"
            class="fixed inset-0 z-10 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div
              class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
            >
              <div
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <span
                class="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
                >&#8203;</span
              >
              <div
                class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
              >
                <div>
                  <div
                    class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-green-100"
                  >
                    <svg
                      class="h-6 w-6 text-green-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <slot name="heading"> Data Berhasil disimpan </slot>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6">
                  <div class="flex items-center gap-2">
                    <base-button
                      color="secondary"
                      fullwidth
                      @click="closeModal"
                    >
                      Selesai
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <template v-if="isLoading && loading">
      <loading />
    </template>

    <!-- Modal untuk konfirmasi pengaturan nilai pengali -->
    <Modal
      :showing="modalDeleteData.visible"
      @close="modalDeleteData.visible = false"
      :showClose="modalDeleteData.visible"
      size="max-w-lg"
    >
      <div>
        <div
          class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-red-100 text-red-700"
        >
          <!-- Heroicon name: outline/check -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-6 w-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>
        </div>
        <div class="mt-3 text-center sm:mt-5">
          <h3
            class="text-lg font-medium leading-6 text-gray-900"
            id="modal-title"
          >
            Konfirmasi Hapus Barang
          </h3>
        </div>
      </div>
      <div class="mt-5 sm:mt-6">
        <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
          <button
            @click="modalDeleteData.visible = !modalDeleteData.visible"
            type="button"
            class="focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
          >
            Batalkan
          </button>
          <button
            type="button"
            @click="deleteData"
            class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
          >
            Hapus
          </button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import Modal from '@/components/base/BaseModal';
import { ToggleButton } from 'vue-js-toggle-button';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'Barang',
  components: {
    Datatable,
    Modal,
    ToggleButton,
    BaseSearch,
  },
  data() {
    return {
      loading: false,
      search: null,
      openDetail: false,
      success: false,
      failed: false,
      retries: 3,
      dataProduct: {},
      isWaitForDownload: false,
      sort: {
        name: 'createdAt',
        direction: 'desc',
      },
      modalDeleteData: {
        visible: false,
        id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'products/getLoading',
      getProducts: 'products/getProducts',
      getProduct: 'products/getProduct',
    }),
  },
  methods: {
    ...mapActions({
      fetchProducts: 'products/fetchProducts',
      exportProducts: 'products/exportProducts',
      downloadProducts: 'products/downloadProducts',
      deleteProduct: 'products/deleteProduct',
      updateProduct: 'products/updateProduct',
      fetchProductById: 'products/fetchProductById',
    }),
    closeModal() {
      this.success = this.failed = false;
      this.openDetail = !this.openDetail;
      this.fetchProducts({
        pageNumber: 1,
        pageSize: 5,
        sortName: this.sort.name,
        sortDirection: this.sort.direction,
        fields: {
          products: 'product_category_name,code,supplier_code,name',
        },
      });
    },
    failedModal() {
      this.failed = !this.failed;
    },
    getProductCategory(id) {
      let category = this.getProducts.included.filter(function (el) {
        return el.id === id;
      });
      return category[0].attributes.name;
    },
    getProductPrice(areaName, buyerType, dataProduct) {
      let productPrice = this.getProduct.included.find(function (include) {
        const result = dataProduct.relationships['product-prices'].data.find(
          (item) => {
            return (
              item.id === include.id &&
              include.attributes.area === areaName &&
              include.attributes['buyer-type'] === buyerType
            );
          }
        );
        if (result?.id) {
          return result.id === include.id;
        }
        return false;
      });
      return productPrice;
    },
    onPageChangeProducts(page) {
      this.fetchProducts({
        pageNumber: page,
        pageSize: 5,
        search: this.search,
        sortName: this.sort.name,
        sortDirection: this.sort.direction,
        fields: {
          products: 'product_category_name,code,supplier_code,name',
        },
      });
    },
    sortData(sortName) {
      if (this.sort.name === sortName) {
        this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
      } else {
        this.sort.name = sortName;
        this.sort.direction = 'asc';
      }
      this.fetchProducts({
        pageNumber: 1,
        pageSize: 5,
        sortName: this.sort.name,
        sortDirection: this.sort.direction,
        fields: {
          products: 'product_category_name,code,supplier_code,name',
        },
      });
    },
    debounceSearch: debounce(function () {
      this.fetchProducts({
        pageNumber: 1,
        pageSize: 5,
        search: this.search,
        sortName: this.sort.name,
        sortDirection: this.sort.direction,
        fields: {
          products: 'product_category_name,code,supplier_code,name',
        },
      });
    }, 300),
    detailBarang(product) {
      this.fetchProductById({
        id: product.id,
        include: 'product-prices',
        fields: { 'product-prices': 'price,area,buyer-type' },
      }).then(() => {
        this.dataProduct = this.getProduct.data;
        this.openDetail = !this.openDetail;
      });
    },
    async downloadProduct() {
      this.isWaitForDownload = true;
      await this.exportProducts();
      const downloadInterval = setInterval(() => {
        this.downloadProducts().then((response) => {
          // response.status == 200 brrt jobs dr backend dah selesai
          // response.status == 202 brrt msh nunggu jobs dr backend
          if (this.retries < 1) {
            clearInterval(downloadInterval);
            this.retries = 3;
          }
          if (response.data.status !== 'exporting' && response.status === 200) {
            clearInterval(downloadInterval);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Master-Barang.xlsx');
            document.body.appendChild(link);
            link.click();
            this.isWaitForDownload = false;
          }
          this.retries = this.retries - 1;
        });
      }, 1000);
    },
    openModalDeleteData(data) {
      this.modalDeleteData.id = data.id;
      this.modalDeleteData.visible = true;
    },
    deleteData() {
      this.deleteProduct({
        id: this.modalDeleteData.id,
      }).then((response) => {
        this.modalDeleteData.id = null;
        this.modalDeleteData.visible = false;
        this.openDetail = false;

        this.fetchProducts({
          pageNumber: 1,
          pageSize: 5,
          sortName: this.sort.name,
          sortDirection: this.sort.direction,
          fields: {
            products: 'product_category_name,code,supplier_code,name',
          },
        });
        if (!response) {
          this.failed = false;
        }
      });
    },
    onActiveChange() {
      const payload = {
        data: {
          type: 'products',
          id: this.dataProduct.id,
          attributes: {
            is_active: this.dataProduct.attributes.is_active,
          },
        },
      };
      this.updateProduct(payload).then(() => {
        this.fetchProducts({
          pageNumber: 1,
          pageSize: 5,
          sortName: this.sort.name,
          sortDirection: this.sort.direction,
          fields: {
            products: 'product_category_name,code,supplier_code,name',
          },
        });
      });
    },
  },
  created() {
    this.fetchProducts({
      pageNumber: 1,
      pageSize: 5,
      sortName: this.sort.name,
      sortDirection: this.sort.direction,
      fields: {
        products: 'product_category_name,code,supplier_code,name',
      },
      include: null,
    });
    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>
