export function createResetOnAfterNoInput() {
  let timeout;
  // eslint-disable-next-line no-unused-vars
  let prevInput = '';

  return (e) => {
    clearTimeout(timeout);

    if (prevInput === '') {
      e.target.value = '';
    }

    prevInput = e.which;

    timeout = setTimeout(() => (prevInput = ''), 500);
  };
}

export function validateFileCsv(...files) {
  return files.every(file => file?.type === 'text/csv')
}