<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="bg-white shadow sm:rounded-lg">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="text-base font-semibold leading-6 text-gray-900">
          Download Bonus
        </h3>
        <div class="mt-5">
          <base-button v-on:click="handleDownload">Download Bonus</base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { downloadFile } from '@/services/utils.service.js';
import BaseButton from '@/components/base/BaseButton.vue';
import dayjs from 'dayjs';

export default {
  components: { BaseButton },
  methods: {
    ...mapActions({
      downloadDbf: 'journal_entries/downloadDbf',
    }),
    handleDownload: function () {
      this.downloadDbf().then((res) => {
        downloadFile(res.data, `bonus-${dayjs().format('YYYY-MM-DD')}.dbf`);
      });
    },
  },
};
</script>
