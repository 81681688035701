<template>
  <base-wrapper>
    <div class="grid grid-cols-1 gap-4">
      <base-card title="Generate Mitra Usaha ND">
        <form
          class="grid grid-cols-1 gap-4"
          @submit.prevent="confirming = true"
        >
          <base-input
            label="Kelipatan"
            type="text"
            :shadow="false"
            fullwidth
            placeholder="Kelipatan"
            v-model="form.qty"
          />
          <div class="col-span-full">
            <base-button :loading="loadingGenerate.visible" :disabled="!valid">{{
              loadingGenerate.visible ? loadingGenerate.text : 'Generate'
            }}</base-button>
          </div>
          <alert v-if="success.visible" @dismiss="onDismissSuccess">
            Generate mitra usaha ND berhasil, klik
            <a :href="success.url" target="_blank" class="font-bold underline"
              >download</a
            >
            untuk melihat file hasil generate.
          </alert>
        </form>
      </base-card>
      <base-card title="Daftar File Export">
        <datatable
          :columns="columns"
          :scroll-x="false"
          :total="generateOfficeNdHistories.meta.page.total"
          :perPage="generateOfficeNdHistories.meta.page.perPage"
          :currentPage="generateOfficeNdHistories.meta.page.currentPage"
          @pagechanged="onPagechanged"
        >
          <template v-slot:tbody="{ classes }">
            <tr
              v-for="history in generateOfficeNdHistories.data"
              :key="history.id"
              :class="[classes.tr, 'bg-white']"
            >
              <td :class="[classes.td, 'font-bold text-gray-900']">
                {{ 'ND-' + history.attributes.start_code }} -
                {{ 'ND-' + history.attributes.end_code }}
              </td>
              <td :class="[classes.td]">
                {{ history.attributes.createdAt | formatDate }}
              </td>
              <td :class="[classes.td]">
                {{ history.attributes.qty | toCurrency }}
              </td>
              <td :class="[classes.td]">
                <a
                  class="font-bold text-green-600"
                  target="_blank"
                  :href="history.attributes.file_url"
                  >Download</a
                >
              </td>
            </tr>
          </template>
        </datatable>
      </base-card>
    </div>
    <base-confirm
      message="Apakah anda yakin akan generate mitra usaha nd?"
      :visible="confirming"
      @close="confirming = false"
      @confirmed="onConfirmed"
    ></base-confirm>
    <loading v-if="loadingHistory" />
  </base-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { requestMixin } from '@/mixins/request/request';
import BaseConfirm from '@/components/base/BaseConfirm.vue';
import Alert from '@/components/base/Alert.vue';

export default {
  mixins: [requestMixin],
  components: { BaseConfirm, Alert },
  data() {
    return {
      loadingGenerate: {
        visible: false,
        text: null
      },
      loadingHistory: false,
      confirming: false,
      form: {
        qty: null,
      },
      success: {
        visible: false,
        url: null,
      },
      generateOfficeNdHistories: {
        data: [],
        meta: {
          page: {},
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    valid() {
      const qty = Number(this.form.qty);

      return qty > 0;
    },
    columns() {
      return [
        {
          id: 'code',
          name: 'Kode Mulai - Kode Akhir',
        },
        {
          id: 'date',
          name: 'Tanggal Export',
        },
        {
          id: 'qty',
          name: 'Jumlah',
        },
        {
          id: 'download',
          name: 'Download',
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    addListener() {
      const userChannel = this.$pusher.subscribe(
        `private-App.Models.User.${this.me.id}`
      );

      userChannel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', (e) => {
        if (e.type === 'App\\Notifications\\MitraUsahaNdGenerated') {
          if (e.status === 'processing') {
            this.loadingGenerate.text = 'Sedang Diproses'
          } else if (e.status === 'failed') {
            this.stopLoadingGenerate()

            this.createAlert({
              data: 'Gagal menarik data',
              status: 'error'
            })
          } else if (e.status === 'finished') {
            this.stopLoadingGenerate()

            this.success.visible = true
            this.success.url = e.file_url

            this.loadHistory()
          }
        }
      })
    },
    removeListener() {
      this.$pusher.unsubscribe(
        `private-App.Models.User.${this.me.id}`
      );
    },
    onDismissSuccess() {
      this.success.visible = false;
    },
    async onConfirmed() {
      this.confirming = false;
      this.success.visible = false;

      this.startLoadingGenerate('Memproses Data');

      const [, error] = await this.request(
        '/api/v1/offices/-actions/generate-mitra-usaha-nd',
        {
          method: 'post',
          data: {
            qty: this.form.qty,
          },
        }
      );

      if (error) {
        this.createAlert({
          data: this.getRequestErrorMessage(error),
          status: 'error',
        });

        this.stopLoadingGenerate();
      }
    },
    onPagechanged(page) {
      this.loadHistory({
        'page[number]': page,
      });
    },
    async loadHistory(params) {
      this.loadingHistory = true;

      const [res, error] = await this.request(
        '/api/v1/generate-office-nd-histories',
        {
          params: {
            'page[size]': 5,
            sort: '-createdAt',
            ...params,
          },
        }
      );

      if (!error) {
        this.generateOfficeNdHistories = res;
      }

      this.loadingHistory = false;
    },
    startLoadingGenerate(text) {
      this.loadingGenerate.text = text;
      this.loadingGenerate.visible = true;
    },
    stopLoadingGenerate() {
      this.loadingGenerate.visible = false;
    },
  },
  created() {
    this.loadHistory();
    this.addListener()
  },
};
</script>
