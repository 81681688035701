<template>
    <base-modal :showing="visible" size="max-w-sm" @close="$emit('close')">
        <div class="text-center space-y-4">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20 text-red-500 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
            <p>{{ message }}</p>
            <div class="grid grid-cols-2 gap-2">
                <base-button fullwidth color="danger" @click="$emit('confirmed')">Yakin</base-button>
                <base-button fullwidth color="white" @click="$emit('close')">Batal</base-button>
            </div>
        </div>
    </base-modal>
</template>

<script>
import BaseModal from './BaseModal.vue'

export default {
    components: {
        BaseModal
    },
    props: {
        message: String,
        visible: Boolean
    },
    emits: ['close', 'confirmed']
}
</script>