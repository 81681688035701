<template>
  <transition
    name="customFade"
    enter-active-class="fadeIn"
    leave-active-class="fadeOut"
  >
    <div
      v-if="visible && order.attributes"
      style="animation-duration: 0.3s"
      class="fixed inset-0 z-10 mx-4 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          v-on:click="handleClose"
        ></div>
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
        >
          <div class="mb-6 flex items-center justify-between">
            <div class="flex items-center space-x-4">
              <div>
                <h3 class="text-lg font-bold leading-6 text-gray-900">
                  {{ title }}
                </h3>
                <p class="mt-1 max-w-2xl text-sm text-gray-500">
                  {{ description }}
                </p>
              </div>
            </div>
            <div>
              <span
                v-if="order.attributes.is_completed"
                class="inline-flex w-24 items-center justify-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
              >
                Selesai
              </span>
              <span
                v-else
                class="inline-flex w-36 items-center justify-center rounded-full bg-red-100 px-3 py-1 text-center text-sm font-medium text-red-800"
              >
                Belum Selesai
              </span>
            </div>
          </div>
          <div class="border-t border-gray-200 py-6">
            <dl class="flex flex-row gap-x-4">
              <div class="w-full">
                <dt class="text-xs text-gray-700">No Invoice</dt>
                <dd class="mt-1 text-sm font-bold text-gray-900">
                  {{ order.attributes.origin_code }} /
                  {{ order.attributes.destination_code }}
                </dd>
              </div>
              <div class="w-full">
                <dt class="text-xs text-gray-700">Tanggal</dt>
                <dd class="mt-1 text-sm font-bold text-gray-900">
                  {{ dayjs(order.attributes.updatedAt).format('ll LT') }}
                </dd>
              </div>
              <div class="w-full">
                <dt class="text-xs text-gray-700">Mitra Usaha</dt>
                <dd class="mt-1 text-sm font-bold text-gray-900">
                  {{
                    order.relationships['destination-office'].data
                      ? getRelationships(
                          getOrder,
                          order.relationships['destination-office'].data.id
                        )?.attributes.code
                      : '-'
                  }}
                </dd>
              </div>
            </dl>
            <dl class="mt-6 flex flex-row gap-x-4">
              <div class="w-full">
                <dt class="text-xs text-gray-700">Dibuat Oleh</dt>
                <dd class="mt-1 text-sm font-bold text-gray-900">
                  {{ order.attributes.created_by?.office?.name }}/{{
                    order.attributes.created_by?.user?.name
                  }}
                </dd>
              </div>
            </dl>
            <dl class="mt-6 flex flex-row gap-x-4">
              <div class="w-full">
                <dt class="text-xs text-gray-700">Nama Member</dt>
                <dd class="mt-1 text-sm font-bold text-gray-900">
                  {{
                    getOrder.data.relationships['destination-office']?.data
                      ? getRelationships(
                          getOrder,
                          getOrder.data.relationships['destination-office'].data
                            .id
                        )?.attributes.name ?? '-'
                      : ''
                  }}
                </dd>
              </div>
              <div class="w-full">
                <dt class="text-xs text-gray-700">Alamat Member 1</dt>
                <dd class="mt-1 text-sm font-bold text-gray-900">
                  {{
                    getOrder.data.relationships['destination-office']?.data
                      ? getRelationships(
                          getOrder,
                          getOrder.data.relationships['destination-office'].data
                            .id
                        )?.attributes.address ?? '-'
                      : ''
                  }}
                </dd>
              </div>
              <div class="w-full">
                <dt class="text-xs text-gray-700">Alamat Member 2</dt>
                <dd class="mt-1 text-sm font-bold text-gray-900">
                  {{
                    getOrder.data.relationships['destination-office']?.data
                      ? getRelationships(
                          getOrder,
                          getOrder.data.relationships['destination-office'].data
                            .id
                        )?.attributes.address2 ?? '-'
                      : ''
                  }}
                </dd>
              </div>
            </dl>
            <dl class="mt-6 flex flex-row gap-x-4">
              <div class="w-full">
                <dt class="text-xs text-gray-700">Kode Upline</dt>
                <dd class="mt-1 text-sm font-bold text-gray-900">
                  {{ upline?.attributes?.code ?? '-' }}
                </dd>
              </div>
              <div class="w-full">
                <dt class="text-xs text-gray-700">Nama Upline</dt>
                <dd class="mt-1 text-sm font-bold text-gray-900">
                  {{ upline?.attributes?.name ?? '-' }}
                </dd>
              </div>
              <div class="w-full"></div>
            </dl>

            <!-- data barang -->
            <div class="my-6 sm:col-span-6" v-if="withOrderDetails">
              <dt class="text-sm font-bold text-gray-900">Data Barang</dt>
              <Datatable :paginated="false" :footer="false" class="mt-2">
                <template v-slot:thead>
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        KODE BARANG
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        JUMLAH
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        BERAT
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        TOTAL HARGA
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        TOTAL BERAT
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:tbody>
                  <tbody>
                    <tr
                      class="bg-white"
                      v-for="(barang, index) in getOrder.included?.filter(
                        (it) => it.type === 'order-details'
                      )"
                      :key="index"
                    >
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      >
                        {{ barang.attributes.product_code }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                      >
                        {{ barang.attributes.product_qty | toCurrency }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                      >
                        {{ barang.attributes.product_weight | toCurrency }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                      >
                        {{ barang.attributes.total_price | toCurrency }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                      >
                        {{ barang.attributes.total_weight | toCurrency }}
                      </td>
                    </tr>
                  </tbody>
                  <!-- Informasi Total -->
                  <tfoot>
                    <tr class="bg-white">
                      <td
                        colspan="3"
                        class="whitespace-nowrap px-6 py-3 pr-12 text-right text-sm text-gray-500"
                      >
                        Total
                      </td>

                      <td
                        class="whitespace-nowrap px-6 py-3 pr-12 text-right text-sm text-gray-500"
                      >
                        {{ order.attributes.grand_total_price | toCurrency }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-3 pr-12 text-right text-sm text-gray-500"
                      >
                        {{ order.attributes.grand_total_weight }}
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </Datatable>
            </div>
            <!-- data barang -->

            <!-- data bonus -->
            <div class="my-6 sm:col-span-6" v-if="withBonus">
              <dt class="text-sm font-bold text-gray-900">Data Bonus</dt>
              <Datatable :paginated="false" :footer="false" class="mt-2">
                <template v-slot:thead>
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        KODE BARANG
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        JUMLAH
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:tbody>
                  <tbody>
                    <tr
                      class="bg-white"
                      v-for="(barang, index) in getOrder.included?.filter(
                        (it) => it.type === 'order-detail-bonuses'
                      )"
                      :key="index"
                    >
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      >
                        {{ barang.attributes.product_code }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                      >
                        {{ barang.attributes.bonus_qty | toCurrency }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </Datatable>
            </div>
            <!-- data bonus -->

          </div>
          <!-- aksi -->
          <div
            class="flex"
            :class="[
              order.attributes.deletedAt ? 'justify-end' : 'justify-between',
            ]"
          >
            <div class="flex gap-x-2" v-if="!order.attributes.deletedAt">
              <button
                @click="handleOpenPrevOrder"
                class="bg-white-600 inline-flex items-center space-x-2 rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
                v-if="order.attributes.previous_order_id"
                title="Lihat Faktur Awal"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                  />
                </svg>
                <span class="hidden md:block">Lihat Faktur Awal</span>
              </button>
              <button
                v-if="!order.attributes.is_valid_for_payment && deleteable"
                @click="handleDelete"
                type="button"
                title="Hapus Faktur"
                class="bg-white-600 inline-flex items-center rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 md:mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
                <span class="hidden md:block">Hapus</span>
              </button>
              <button
                v-if="!order.attributes.is_valid_for_payment && !order.attributes.is_for_registration && editable"
                @click="handleEdit"
                title="Faktur Ulang"
                class="bg-white-600 inline-flex items-center rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 md:mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                  />
                </svg>
                <span class="hidden md:block">{{
                  order.attributes.canceled_from ? 'Faktur Ulang' : 'Edit'
                }}</span>
              </button>
              <button
                @click="handlePrint"
                title="Cetak Faktur"
                class="bg-white-600 inline-flex items-center rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6 md:mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
                  />
                </svg>
                <span class="hidden md:block">Cetak</span>
              </button>
              <button
                @click="handleDownload"
                title="Download Faktur"
                class="bg-white-600 inline-flex items-center rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6 md:mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>
                <span class="hidden md:block">Download</span>
              </button>
            </div>
            <div class="flex justify-end">
              <button
                v-if="withValidation && canValidate"
                @click="handleValidate"
                class="focus:outline-none mr-2 inline-flex items-center rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-2 h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                  />
                </svg>
                Validasi
              </button>
              <button
                @click="handleClose"
                type="button"
                class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
              >
                Tutup
              </button>
            </div>
          </div>
        </div>
      </div>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :preview-modal="true"
        :enable-download="false"
        :paginate-elements-by-height="5000"
        :filename="'fdsfd'"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a7"
        pdf-orientation="portrait"
        pdf-content-width="100mm"
        ref="html2Pdf"
      >
        <PdfContent slot="pdf-content" :order="getOrder" />
      </vue-html2pdf>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Datatable from '@/components/base/Datatable';
import PdfContent from '@/layouts/LayoutPrintA4.vue';
import VueHtml2pdf from 'vue-html2pdf';
import { StorageService } from '@/services/storage.service';

export default {
  name: 'DetailModal',
  components: {
    Datatable,
    VueHtml2pdf,
    PdfContent,
  },
  props: {
    title: {
      type: String,
      default: 'Detail Cashbill',
    },
    description: {
      type: String,
      default: 'Data Cashbill',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    deleteable: {
      type: Boolean,
      default: true,
    },
    withOrderDetails: {
      type: Boolean,
      default: true,
    },
    withBonus: {
      type: Boolean,
      default: true,
    },
    office: Object,
    withValidation: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close', 'refresh', 'edit', 'delete', 'open-prev-order'],
  data() {
    return {
      paymentAccordion: {
        active: null,
      },
      print: {
        preview: false,
        download: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      getOrder: 'orders/getOrder',
      isLoading: 'orders/getLoading',
      getPaymentPaymentMethods: 'payments/getPaymentMethods',
      getLoadingPaymentMethods: 'payments/getLoadingPaymentMethods',
    }),
    canEdit() {
      return this.editable && !this.order.attributes.is_valid_for_payment;
    },
    canValidate: function () {
      return (
        !this.order.attributes.is_valid_for_payment &&
        !this.order.attributes.deletedAt &&
        this.order.attributes.created_by.office.id ===
          StorageService.getUser().office_id
      );
    },
    filename() {
      const order = this.getOrder;
      return `${order.data.attributes.origin_code}_${
        order.data.attributes.destination_code
      }_${dayjs(order.data.attributes.createdAt).format('L LTS')}`;
    },
    upline: function () {
      const refferalUplineRelation = this.office.included?.find(
        (include) =>
          include?.type === 'upline-relations' &&
          include?.attributes?.relation_type === 'referral'
      );
      const officeUpline = refferalUplineRelation
        ? this.office.included.find(
            (include) =>
              include?.type === 'offices' &&
              include?.attributes?.code ===
                refferalUplineRelation?.attributes?.code
          )
        : {};

      return officeUpline;
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      validateOrder: 'orders/validate',
      fetchPaymentPaymentMethods: 'payments/fetchPaymentPaymentMethods',
    }),
    getRelationships(resource, id) {
      let data = resource.included?.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    handleClose: function () {
      this.$emit('close');
    },
    togglePaymentAccordion(id) {
      this.paymentAccordion.active =
        this.paymentAccordion.active === id ? null : id;

      if (this.paymentAccordion.active === id) {
        this.fetchPaymentPaymentMethods({
          id,
          include: 'office-bank',
          fields: {
            'payment-methods': 'payment_amount,office-bank,balance_used',
            'office-banks': 'bank_name',
          },
        });
      }
    },

    handleValidate: function () {
      const payload = {
        id: this.order.id,
      };

      this.validateOrder(payload).then((response) => {
        if (response) {
          this.$emit('close');
          this.$emit('refresh');
        }
      });
    },

    handleEdit: function () {
      this.$emit('edit', this.order.id);
    },

    handlePrint: function () {
      this.print.preview = true;
      this.print.download = false;
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
      }, 0);
    },

    handleDownload: function () {
      this.print.preview = false;
      this.print.download = true;
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
      }, 0);
    },

    handleDelete: function () {
      this.$emit('delete');
    },

    handleOpenPrevOrder: function () {
      this.$emit('open-prev-order');
    },
  },
};
</script>
